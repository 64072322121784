/**
* Handles form validationa and submision.
* @param {string} formId - Id of the form to boostrap
* @param {function} callback - function to call after form submit 
* @return  Object
*/
SEAGATE.form = function (formId, callback) {
    'use strict';
    
    if(!formId) {
        return;
    }
	
	//remove any russia field a select where this module is loaded
	$('#' + formId + ' select option[value="RU"]').remove();
	
	//thi sis a fix for ru
	if($('html').attr('lang') === 'ru-ru'){
		 $('#' + formId).hide();
		 
		//oz campaign page soltuion
		 if( document.getElementById('oz-form') ) {
			 $('.header-contact-form').remove();
			 $('.sticky-contact-form').remove();
			 $('#sticky-form-controls').remove();
		 }
		
		//removes form node
		if( $('.form-node') ) {
			$('.form-node').remove();
		}
		
		//dont let the form initialize;
		return;
	}
    
    var form = {
        form: null,
        dirty: false,
        valid: false,
        clean: true,
        children: {}
    },
    
    callback = callback || function(){},
    
    values = {
      dirtClass: 'stx-dirty',
      validClass: 'stx-valid',
      requiredClass: 'stx-required',
      invalidClass: 'stx-invalid',
      formValidClass: 'stx-form-valid',
      formInvalidClass: 'stx-form-invalid',
      watching: 'watching'
    },
    
    bootstrapForm = function() {
        var children = [], 
            element = {},
            nodeLength,
            doc = document;
        
        form.form = doc.getElementById(formId);
        children = doc.querySelectorAll('#' + formId + ' input, #' + formId + ' select, #' + formId + ' button');
        nodeLength = children.length;
        
        for(var i = 0; i < nodeLength; i++) {
            // reset element and validations array
            element = {
                validations: []
            };
            
            // if a name was provided add it to the childrens array
            if(children[i].name && children[i].type !== 'hidden') {
                children[i].className = values.watching + ' ' + children[i].className;
                
                
                if(children[i].type === 'submit') {
                    form.button = children[i];
                }else if(children[i].type !== 'radio') {
                    element.element = children[i];
                    element.name = children[i].name;
                    
                    //required
                    if (children[i].attributes.required) {
                        element.validations.push({
                            type : 'required',
                            value : true
                        });
                        element.required = true;
                    } else {
                        element.required = false;
                    }

                    //urls
                    children[i].type === 'url' ? element.validations.push({
                        type : 'url',
                        value : true
                    }) : false;
                    
                    //numbers
                    children[i].attributes.stxnumber ? element.validations.push({
                        type : 'numeric',
                        value : true
                    }): false;
                    
                   
                    //maxlength
                    children[i].attributes.maxlength ? element.validations.push({
                        type: 'maxlength',
                        value: children[i].attributes.maxlength.value,
                    }) : null;
                    
                    //minlenght
                    children[i].attributes.minlength ? element.validations.push({
                        type : 'minlength',
                        value : children[i].attributes.minlength.value
                    }) : null;
                    
                    //email
                    children[i].type === 'email' ? element.validations.push({
                        type : 'email',
                        value : children[i].attributes.type
                    }) : null;
                    
                    // if the element is required mark as not valid
                    if(element.required){
                        element.dirty = false;
                        element.valid = false;
                    }else{
                        //else mark as valid but not dirty
                       element.dirty = false;
                       element.valid = true; 
                    }
                    
                    //set current error to display
                    children[i].error = '';
                    children[i].prevError = '';
                    
                    //add to list of children
                    form.children[children[i].name] = element; 
                }
            }
        } 
         
        asignEvents();
    },
    
    validateField = function(elementObj, tabbed, validateClean) {
        var validations = elementObj.validations,
            value, valid = 'unchecked';
      
        for( var i = 0; i < validations.length; i++ ){
           
            if(!valid) {
                break;
            }
            
            value = trim(elementObj.element.value);
            
            if (validations[i].type === 'required' && elementObj.element.type !== 'checkbox') {
                
                if (!value) {
                    elementObj.valid = valid = false;
                } else if (value && !elementObj.corrected && elementObj.dirty) {
                    //if has value, and corrected is false and element is dirty
                    elementObj.corrected = true;
                    elementObj.valid = true;
                    valid = true;
                } else {
                    elementObj.valid = true;
                    valid = true;
                }
               
            }else if(validations[i].type === 'required' && elementObj.element.type === 'checkbox') {
                
                //required checkboxes
                if(elementObj.element.type === 'checkbox' && !elementObj.element.checked && elementObj.dirty) {
                    elementObj.valid = valid = false;
                }else if(elementObj.element.type === 'checkbox' && elementObj.element.checked) {
                    elementObj.dirty = true;
                    elementObj.valid = true;
                    valid = true;
                }
            }
           
            if (validations[i].type === 'email') {
                elementObj.valid = valid = validateEmailFormat(value);
            }
            
            if (validations[i].type === 'numeric') {
                elementObj.valid = valid = validateNumeric(value);
            }
            
            if (validations[i].type === 'stx-numeric') {
                elementObj.valid = valid = validateNumeric(value);
            }
            
            if (validations[i].type === 'minlength' && value.length < validations[i].value) {
                //console.log('should be: ' + validations[i].value + ' but it is ' + value.length + ' chars long');
                elementObj.valid = valid = false;
            }
            
            if (validations[i].type === 'maxlength' && value.length > validations[i].value) {
                //console.log('should be: ' + validations[i].value + ' but it is ' + value.length + ' chars long');
                elementObj.valid = valid = false;
            }
            
            if (validations[i].type === 'url') { 
                // TO DO: add url validation
                elementObj.valid = valid = validateUrl(value);
            }
            
            // if a previous error exist assign as as previous
            // will be used hide the error when the field is corrected
            elementObj.prevError = elementObj.error;
            
            if(!valid) {
                elementObj.error = validations[i].type;
            }
            
        }
        
        //check if it is being corrected and mark it as
        if (!elementObj.corrected && elementObj.dirty) {
            elementObj.corrected = true;
        }
        
        //mark as dirty if it's not a check box
        //check box is marke dirty only when check
        if(elementObj.element.type !== 'checkbox' && !tabbed) {
            elementObj.dirty = true;
        }
        
        if(validateClean){
            elementObj.dirty = true;
        }
        
    },
    
    
    //validate the entire form;
    validateForm = function(validateAll) {
       var children = form.children,
           validateEverything = validateAll || false;
           
       for (var child in children) {
           digestForm(children[child].element.name, true, validateEverything);
       }
    },
    
    updateFormStatus = function() {
          var children = form.children, 
              errorClass = '', 
              errorFound = false,
              className = ''; 
              
          //need a check if it was previously marked invalid
          //or if it was corrected
          for (var child in children) {
              className = children[child].element.className;
              
              if(children[child].dirty && !children[child].valid) {
                  
                  if(className.indexOf(values.invalidClass) === -1){
                    children[child].element.className = values.invalidClass + ' ' + children[child].element.className;
                  }
                  
                  if(children[child].error) {
                      errorClass = '.' + children[child].name + '-' + children[child].error;
                      
                      $(errorClass).show();
                  }
                  
                  if(children[child].prevError !== children[child].error) {
                    //hide previous error 
                    
                    $('.' + children[child].name + '-' + children[child].prevError).hide(); 
                  }
                  
                  errorFound = true;
                  
              }else if(children[child].dirty && children[child].valid && children[child].corrected) {
              
                  //hide the previous error
                  $('.' + children[child].name + '-' + children[child].prevError).hide();
                  
                  //remove all toher classes from element 
                  children[child].element.className = values.watching;
                            
                  //remove error and previous error since they have been fixed
                  children[child].error = '';
                  children[child].prevError = '';
              }else {
                  //check if the field is valid but not dirty
                  //may happen when the user refreshes the page but filled some of the from
                  if(!children[child].valid){
                    errorFound = true;      
                  }
                  
              }
          }// for loop
          
          //mark form as valid/invalid
          form.valid = errorFound ? false : true;
         
          //if a general error as added, hide it after an element has been changed
          $('#' + form.form.name + '-general-error').hide();
         
    },
    
    digestForm = function(nodeName, tabbed, validateClean) {         
        var dirtyNodes = [],
            children = form.children,
            foundInvalid;
        
        //validate single
        if(nodeName) {
            validateField(children[nodeName], tabbed, validateClean);
            updateFormStatus();
            return;
        }
       
    },
    
    asignEvents = function() { 
       $('.' + values.watching).on('blur change', function(evt) {
            var e = evt.target || evt.srcElement;
            digestForm(e.name);
        });
       
        $(form.form).on('submit', function(e) {
            e.preventDefault();
            
            //if the form is not valid, attempt to validate it on sumbit
            if(!form.valid){
                validateForm(true); 
            }
            
            //check again after it has been validated,
            //if it didn't validate prevent submit and show general error.
            if(!form.valid){
                $('#' + form.form.name + '-general-error').fadeIn();
                return false;  
            }
            
            //else submit the form and do not follow
            submitForm($(this));
            return false;  
        });
        
        $(form.form).keydown(function(e) {
            if(e.keyCode === 9){
                validateForm();
            }
        });
    },
    
    submitForm = function(form) {
        var form = form, xdr;
        
        if (window.XDomainRequest) {
            jQuery.ajaxTransport(function (s) {
                if (s.crossDomain && s.async) {
                    if (s.timeout) {
                        s.xdrTimeout = s.timeout;
                        delete s.timeout;
                    }
                    
                    return {
                        send: function (_, complete) {
                            function callback(status, statusText, responses, responseHeaders) {
                                xdr.onload = xdr.onerror = xdr.ontimeout = jQuery.noop;
                                xdr = undefined;
                                complete(status, statusText, responses, responseHeaders);
                            }
                            xdr = new XDomainRequest();
                            xdr.onload = function () {
                                callback(200, "OK", {
                                    text: xdr.responseText
                                }, "Content-Type: " + xdr.contentType);
                            };
                            xdr.onerror = function () {
                                callback(404, "Not Found");
                            };
                            xdr.onprogress = jQuery.noop;
                            xdr.ontimeout = function () {
                                callback(0, "timeout");
                            };
                            xdr.timeout = s.xdrTimeout || Number.MAX_VALUE;
                            xdr.open(s.type, s.url);
                            xdr.send((s.hasContent && s.data) || null);
                        },
                        abort: function () {
                            if (xdr) {
                                xdr.onerror = jQuery.noop;
                                xdr.abort();
                            }
                        }
                    };
                }
            });
        }
        
        $.ajax({
            type: $(form).attr("method"),
            url: $(form).attr("action"),
            data: $(form).serialize(),
            complete: function (data, status) {
                //call the passed in call back if it was given
                callback();
                //just in case it was showing before, also hiding it on field validation
                $('#' + form.name + '-general-error').hide();
            }
        });
        
        return false;
           
    },
    
    validateEmailFormat = function (email) {
        email = trim(email);
        
        if (!email) {
            return false;
        }
        
        var regex = /([a-z0-9][-a-z0-9_\+\.]*[a-z0-9])@([a-z0-9][-a-z0-9\.]*[a-z0-9]\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|tv|me|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\.{3}[0-9]{1,3}))+$/;
        
        return regex.test(email) ? true : false;
        
    },
    
    validateAlpha = function  (str) {
        str = trim(str);
        if (!str) {  
            return false;
        }
        
        var alpha = /[^a-zA-Z]/;
        if (alpha.test(str)) {
            // it has non alphas
            return false;
        } else {
            return true;
        }
       
    },
    
    validateUrl = function(str) {
        str = trim(str);
                
        if(!str) { 
            return false;
        }
       
        var url = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        
        if (url.test(str)) {
            // it has not url like
            return true;
        } else {
            return false;
        }
        
    },
    
    validateNumeric = function (str) {
        str = trim(str);
        str = parseInt(str);
        if (!str) {
            return false;
        }
        
        var numeric = /[^0-9]/;
        if (numeric.test(str)) {
            // it has nubmer
            return false;
        } else {
            return true;
        }
    },
    
    trim  = function (str) {
        return str.replace(/^\s+|\s+$/gm,'');
    };
    
    bootstrapForm();
    
    // module interace
    return {
        bootstrapForm: bootstrapForm,
        isEmail : validateEmailFormat,
        isAlpha : validateAlpha,
        isNumberic : validateNumeric
    };
    
};