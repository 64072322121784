
SEAGATE.components = (function () {
    'use strict';
    var anchorNav = (function () {

        var DEFAULTS = {
            sectionClass :'',
            appendingDivId : '',
            alignLeft: false,
            animate: true,
            vertPosition: null,
            scrollOffset: -1,
            bottomPosition: 0,
            useCurrentIdAsAnchor : false
        },
        
        currentSection = -1,
        sectionsIds = [],
        sections = [],
        anchorCtas = [],
        params = {},
        numSections = null,
        scrollInterval =  false,
        last = false,
        docHeight = $(document).height();
              
        /**
        * creates a side anchor nav for a page
        * @params {string} params.anchorId  - id to be used to create the anchor sections
        * @params {string} params.sectionClass - class of sections to be anchored to
        * @params {boolean} params.alignLeft - nav float
        * @params {boolean} params.animate - should the nav fade in? 
        * @params {string} params.vertPosition -top postion, in % or px (example 50%)
        * @return img || none
        */
        var init = function(args) {
            // if no section class name passed and no container to append nav to
            // simply return
            if (!args.appendingDivId && !args.sectionClass) {
                return;
            }
            
            // set defaults to ensure no errors
            params = {
                sectionClass: args.sectionClass || DEFAULTS.sectionClass,
                appendingDivId: args.appendingDivId || DEFAULTS.appendingDivId,
                alignLeft: args.alignLeft || DEFAULTS.alignLeft,
                animate: args.parameters || DEFAULTS.parameters,
                vertPosition: args.vertPosition || DEFAULTS.vertPosition,
                scrollOffset: args.scrollOffset || DEFAULTS.scrollOffset,
                bottomPosition: args.bottomPosition || DEFAULTS.bottomPosition,
                useCurrentIdAsAnchor : args.useCurrentIdAsAnchor || DEFAULTS.useCurrentIdAsAnchor
            };
            
            createNav();
            setScrollEvents(); 
            return this;
        };
                         
        var createNav = function () {
            var count = 1;
           
            $('.' + params.sectionClass).each(function () {
                if (params.useCurrentIdAsAnchor) {
                    sectionsIds.push('#' + $(this).attr('id'));
                } else {
                    $(this).attr('id', params.sectionClass + '-' + count);
                    sectionsIds.push('#' + params.sectionClass + '-' + count++);
                }
                
                // cache sections to anchor to avoid having to fetch them from
                // the DOM every time the user clicks the scroll nav.
                sections.push($(this)); 
            });
            
            // set num sections for reuse
            numSections = sectionsIds.length;
           
           createNavHTML();
           createInteractiveStyleProperties();
           
           updateSectionMarker(sectionsIds[0]);
        };
                 
        var createInteractiveStyleProperties = function () {
            var style = document.createElement('style'),
                head  = document.getElementsByTagName('head')[0],
                styles = '';
                
            style.id = 'anchor-nav-style-sheet';
            style.type = 'text/css';  
            styles = '.nav-anchor-element:hover{background: #00c4c4;}';
            styles += '#main-anchor-nav-control:hover{background: rgba(51, 214, 214, .2)}';
            styles += '.current-anchor-element{background: #00c4c4;}';
            
            if (style.styleSheet) {
                style.styleSheet.cssText = styles;
            } else {
                style.appendChild(document.createTextNode(styles));
            }
                
            if ( !document.getElementById('anchor-nav-style-sheet') ) {
               head.appendChild(style);
            }
        };
        
        var createNavHTML = function () {
            var divCont = document.createElement('div'),
                container = document.createElement('div'),
                bucket = null;
            
            divCont.className = 'anchor-nav-section-elements hidden-phone';
            container.id = 'anchor-nav-container';
            container.style.position = 'fixed';
            
            if (params.vertPosition !== null) {
                container.style.top = params.vertPosition ;    
            } else {
                container.style.bottom = params.bottomPosition;
            }
            
            if (params.alignLeft) {
                container.style.left = '20px';
            } else {
                container.style.right = '20px';
            }
               
            for (var i = 0; i < numSections; i++) {
                bucket= document.createElement('a');
                bucket.className = 'nav-anchor-element';
                
                
                bucket.style.display = 'block';
                bucket.style.border = '1px solid #00c4c4';
                bucket.style.width = '7px';
                bucket.style.height = '7px';
                bucket.style.margin = '0 0 5px 0';
                bucket.style.borderRadius = '50%';
                bucket.style.MozBorderRadius = '50%';
                bucket.style.WebkitBorderRadius = '50%';
                
                if (params.useCurrentIdAsAnchor) {
                    bucket.href = sectionsIds[i];
                }else {
                    bucket.href = '#' + params.sectionClass + '-'+ (i + 1);
                }
                
                // cache cta anchors
                anchorCtas.push(bucket);
                // append to container
                divCont.appendChild(bucket); 
            }
            // create main arrow nav
            container.appendChild(divCont);
            
            bucket = document.createElement('a');
            bucket.id = 'main-anchor-nav-control';
            bucket.style.backgroundImage='url("/www-content/web-apps/_shared/images/tonext.png")';
            bucket.style.display = 'block';
            bucket.style.position = 'absolute';
            bucket.style.width = '45px';
            bucket.style.height = '45px';
            bucket.style.border = '1px solid #00c4c4';
            bucket.style.borderRadius = '50%';
            bucket.style.MozBorderRadius = '50%';
            bucket.style.WebkitBorderRadius = '50%';
            bucket.style.bottom = '0px';
            bucket.style.cursor = 'pointer';
            bucket.style.zIndex = '999';
            
            if (params.alignLeft) {
                bucket.style.left = '20px';
            } else {
                bucket.style.right = '20px';
            }
            
          
            container.appendChild(bucket);
            // apend to dom to the specified container
            if (params.animate) {
                container.style.display = 'none';    
            }
            
            // append nav to DOM
            document.getElementById(params.appendingDivId).appendChild(container);
            
            // fade in with jquery
            if (params.animate) {
                $(container).fadeIn(600);
            } 
        };
        
        var setCurrentSection = function (stringId) {
          
            for (var i = 0; i < numSections; i++) {
                if (i === numSections - 1) {
                    last = true;
                    currentSection = -1;
                    return;
                }
                
                if (stringId === sectionsIds[i]) {
                    currentSection = i;
                    return;
                }
            }
        };
        
        var scrollTo = function (obj) {
            var targetTop = null;
            
            // trigger only when the user clicks the large down button
            if (obj === '' || obj === undefined) {
                            
                if (sectionsIds[currentSection + 1]) {
                    
                    // get new section id
                    targetTop = sectionsIds[currentSection + 1];
                    
                    // set the new current section(numeric id);
                    setCurrentSection(targetTop);
                    // get pixel position of new section
                    targetTop = $(targetTop).offset().top;
                    
                    
                    if (currentSection === -1 && last) {
                        // if it the last step set last marker
                        updateSectionMarker(sectionsIds[numSections -1]);
                    } else {
                        // set marker
                        updateSectionMarker(sectionsIds[currentSection]);
                    }
                    
                }
            }
            
            if (typeof obj === 'object') {
               
                targetTop = $(obj).attr('href');
                                
                // set current position
                setCurrentSection(targetTop);
               
                // set target pixel position
                targetTop = $(targetTop).offset().top;
                
            }
               
            if (targetTop || targetTop === 0) {
               var width = $(window).width();
               
               if (params.scrollOffset && width >= 768) {
                   targetTop = parseInt(targetTop) - params.scrollOffset;
               } else if (params.scrollOffset && width < 768) {
                   targetTop = parseInt(targetTop) - (params.scrollOffset/2);
               }

               $('html, body').animate({ scrollTop : targetTop }, 500, function () {
                   setScrollInterval();
               });
               
               changeScrollDirection();
               
            }
           
        };
        
        var updateSectionMarker = function (obj) {
            
            if (typeof obj === 'object') {
                
                $('.current-anchor-element').removeClass('current-anchor-element');
                $(obj).addClass('current-anchor-element');
                
                return;
            }
            
            if (typeof obj === 'string') {
                
                $('.current-anchor-element').removeClass('current-anchor-element');
                
                for ( var i = 0; i < numSections; i ++) {
                    if ($(anchorCtas[i]).attr('href') === obj) {
                       $(anchorCtas[i]).addClass('current-anchor-element');
                    }
                }
               
               return;
            }
            
            if (!last && obj === undefined) {
                $('.current-anchor-element').removeClass('current-anchor-element');
                
                $('.scroller').each(function () {
                    if ($(this).attr('href') === sectionsIds[numSections -1]) {
                       $(this).addClass('current-anchor-element'); 
                    }
                });
            }
            
        };
        
        var changeScrollDirection = function () {
        
            if (last && currentSection === -1) {
                $('#main-anchor-nav-control').css({ 
                    'background': 'url("/www-content/web-apps/_shared/images/totop.png")'
                });
                
                last = false;
            }
            
            if (currentSection === 0 && last === false) {
               $('#main-anchor-nav-control').css({ 
                    'background': 'url("/www-content/web-apps/_shared/images/tonext.png")'
                });
             
            } 
            
            if (currentSection >= 0 && last === false) {
               $('#main-anchor-nav-control').css({ 
                    'background': 'url("/www-content/web-apps/_shared/images/tonext.png")'
                });
            }     
        };
    
    
        var setMarkerOnScroll = function () {
            var winTop = ( $(window).scrollTop()), 
                winHeight = $(window).height(), 
                winBottom = winTop + winHeight, 
                setMarkerPixelNum = winTop + params.scrollOffset, 
                offsetTop = null;
                   
            for (var i = 0; i < numSections; i++ ) {
                offsetTop = $(sections[i]).offset().top;
                
                if( offsetTop >= winTop && offsetTop <= setMarkerPixelNum ) {
                    updateSectionMarker('#' + $(sections[i]).attr('id'));
                    setCurrentSection('#' + $(sections[i]).attr('id'), i);
                    changeScrollDirection();
                }
                
                if (docHeight < winTop ) {
                    updateSectionMarker(sectionsIds[numSections - 1]);
                    setCurrentSection(sectionsIds[numSections - 1]);
                    changeScrollDirection();
                }
            }
        };
        
        var setScrollInterval = function () {
            
            if (!scrollInterval) {
               scrollInterval = setInterval(function () {
                    setMarkerOnScroll('.' + params.sectionClass);
                }, 100); 
            }
        };
        
        var setScrollEvents = function () {
            setScrollInterval();
            // active anchor nave
            $('.nav-anchor-element').on('click', function (e) {
                e.preventDefault();
                
                // stop scrollInterval;
                clearInterval(scrollInterval);
                scrollInterval = false; 
                
                updateSectionMarker($(this));
                
                scrollTo($(this));
            });
            
            $('#main-anchor-nav-control').on('click', function (e) {
                e.preventDefault();
                
                // stop scrollInterval;
                clearInterval(scrollInterval);
                scrollInterval = false;
                updateSectionMarker();
               
                scrollTo();       
            });
        };
        
        // anchorNav public interface   
        return { 
            init : init
        };
        
     })();// anchorNav end
     
     
    /**
    * creates a slider with the given params
    * the slides and ctas are paired in ther they are in the DOM
    * Takes object with the following properties
    * set all slides to display none. The first slide will be set to display block on page load
    *
    * @param {string} params.containerId - used to check if the slider is in window view
    * @param {string} params.slidesClass - class of the slides
    * @param {string} params.ctaClass - class of ctas
    * @param {string} params.currentSlideClass - set current class for ctas(for styling)
    * @param {string} params.currentCtaClass - set current class for ctas(for styling)
    * @param {number} params.speed - how fast to auto animate slides
    * @param {number} params.onEvent - event to animate defaults to 'mouseover touchstart'(a jquery style string)
    * @param {string} params.selfAnimate - slide on it's own or on event
    * @param {string} params.transition - (fade or hide and show)
    * @return  none
    */
    
    // slider object
    var onEventSlider = function (args) {
        
        if (!args.ctaClass && !args.slidesClass) {
            return;
        }
        
        var slides = [],
            slideCount = 0,
            cta = [],
            sliderTop = null,
            currentSlide = 0,
            inView = false,
            params = {},
            animateInterval = null;
            
        // set some defaults if they dont exist
        params.currentCtaClass = args.currentCtaClass || args.ctaClass + '-current';
        params.currentSlideClass = args.currentSlideClass || args.slidesClass + '-current';
        params.speed = args.speed || 4000;
        params.onEvent = args.onEvent || 'mouseover touchstart';
        params.transition = args.transition || 'fade';
        params.selfAnimate = args.selfAnimate || false;
        params.ctaClass = args.ctaClass;
        params.slidesClass = args.slidesClass;
        params.containerId = args.containerId;
        
        var prepSlider = function () {
           var count = 0;
           
           $('.' + params.slidesClass).each(function () {
               $(this).attr('id', params.slidesClass + '-' +  count);
               slides.push($(this));
               count++;
            });
            
            slideCount = slides.length - 1;
    
            // add need id's to cta items
            count = 0;
            $('.' + params.ctaClass).each(function () {
               $(this).attr('id', params.ctaClass + '-' + count);
               cta.push($(this));
               count++;
            });
            //chache top position of the document in relation to the document
            sliderTop = $('#' + params.containerId).offset().top;
            //sliderTop = sliderTop.offset().top;    
        };   
            
        var addCurrentClass = function () {
            $(slides[currentSlide]).addClass(params.currentSlideClass);
            $(cta[currentSlide]).addClass(params.currentCtaClass);            
        };
        
        var toggleClassName = function (currentId, nextId) {
            
            // remove class from current slide/cta
            $(slides[currentId]).stop().removeClass(params.currentSlideClass); 
            $(cta[currentId]).stop().removeClass(params.currentCtaClass);
            
            // add class to the selected slide/cta
            $(slides[nextId]).addClass(params.currentSlideClass);
            $(cta[nextId]).addClass(params.currentCtaClass);
        };
        
        var removeCurrentClass = function () {
           $(slides[currentSlide]).stop().removeClass(params.currentSlideClass); 
           $(cta[currentSlide]).stop().removeClass(params.currentCtaClass);
        };
        
        var animateInView = function () {
            var self = this, thisTop = null, slider = $('#' + params.containerId),
            inWindowInterval = null, winTop = null, winHeight = null, winBottom = null;
            
            
            inWindowInterval = setInterval(function () {
                // check if in view, if it is set an interval to animate
                winTop = ( $(window).scrollTop());
                winHeight = $(window).height();
                winBottom = winTop + winHeight;
                 
                thisTop = slider.offset().top;
                
                // chech to see if the container is with the window.
                // logic assumes the container and the slides have the same top edge.
                if ( winTop < sliderTop && winBottom > sliderTop) {
                   if (params.selfAnimate && !animateInterval) {
                         animateInterval = setInterval(function () {
                             
                             switch(params.transition) {
                                 case 'none' :
                                    toggleClassName(currentSlide, currentSlide === slideCount ? 0 : currentSlide + 1); 
                                    
                                    $(slides[currentSlide]).css('display', 'none');
                                    // set current slide
                                    currentSlide === slideCount ? currentSlide %= slideCount : currentSlide++;
                                      
                                    // show next
                                    $(slides[currentSlide]).css('display', 'block');
                                    break;
                                          
                                 case 'fade' : 
                                 
                                    $(slides[currentSlide]).fadeOut(400, function () {
                                        // set current slide
                                        toggleClassName(currentSlide, currentSlide === slideCount ? 0 : currentSlide + 1);
                                        currentSlide === slideCount ? currentSlide %= slideCount : currentSlide++;
                                        
                                        // show next
                                        $(slides[currentSlide]).fadeIn(400); 
                                      });
                                      break;
                                          
                                 default: 
                                    // hide current
                                    $(slides[currentSlide]).fadeOut(400, function () {
                                        
                                        toggleClassName(currentSlide, currentSlide === slideCount ? 0 : currentSlide + 1);
                                        // set current slide
                                        currentSlide === slideCount ? currentSlide %= slideCount : currentSlide++;
                                  
                                        // show next
                                        $(slides[currentSlide]).fadeIn(400); 
                                    });
                                        
                             }// end switch
                             
                         }, params.speed);// end interval
                    } // end if params.selfAnimiate                  
                    
                } else {
                    // make sure the current slide is displaying when not in view
                    // prevents the image from disappearing if scrolled away during a fade
                    
                    clearInterval(animateInterval);
                    animateInterval = false; 
                    /*
                    $(slides[currentSlide]).css('display', 'none');
                    
                    toggleClassName(currentSlide, 0);
                    currentSlide = 0;
                    
                    $(slides[currentSlide]).css('display', 'block');*/
                    
                    
                }
            
            }, 100);// end inWindowInterval            
        };
        
        var setEventHandlers = function () {
           addCurrentClass(); 
           animateInView();
               
           // create event listener
           $('.' + params.ctaClass).on(params.onEvent, function (e) {
                // stop auto rotation
                clearInterval(animateInterval);
                e.preventDefault();
                if (!$(this).hasClass(params.currentCtaClass)) {
                   
                   // move current class here.
                   var id = $(this).attr('id');
                       id = id.split(params.ctaClass + '-')[1];
                   // check that current slide is not the same as active
                   
                   if (id == currentSlide) {
                       return false;
                   }
                   
                   toggleClassName(currentSlide, id);
                   
                    switch(params.transition) {
                        case 'none' :
                            $(slides[currentSlide]).css('display', 'none');
                            // hide current
                            currentSlide = id;
                            // reset current slide
                            $(slides[id]).css('display', 'block');
                    
                            break;
                    
                        case 'fade' :
                            $(slides[currentSlide]).stop().fadeOut(300, function () {
                                currentSlide = id;
                                // reset current slide
                                // show new slide
                                $(slides[id]).fadeIn(300);
                    
                            });
                            break;
                    
                        default:
                            $(slides[currentSlide]).stop().fadeOut(300, function () {
                                currentSlide = id;
                                // reset current slide
                                // show new slide
                                $(slides[currentSlide]).fadeIn(300);
                    
                            });
                   }// endSwitch
               }// end if hasClass
           });// end .on(event)
        };// end setEventHandlers();
        
        var init = function () {
           prepSlider();
           // set first slide to default slide
           $(slides[currentSlide]).css('display', 'block');
           
            if ($(window).width() > 767) { 
                setEventHandlers();
            }
        }; 
        
        return init();
    };
     // components public interfiace    
     return {
        anchorNav: anchorNav,
        onEventSlider: onEventSlider
     };
})();// end components
