//makes add the approparite href attribute to a link for social media sites. It currently is not being us
/*
example markup
<div class="social-share">
   <a id="facebook-share" href="https://www.facebook.com/sharer/sharer.php" class="fa fa-facebook-square" target="_blank">Facebook </a>
   <a id="twitter-share" href="https://www.twitter.com/share?" class="fa fa-twitter-square" target="_blank">Twitter</a>
   <a id="linkedin-share" href="https://www.linkedin.com/shareArticle?" class="fa fa-linkedin-square" target="_blank">LinkedIn</a>
   <a id="google-share" href="https://plus.google.com/share" class="fa fa-google-plus-square">Google Plus</a>
</div>

The code expects to have the an id containing 'facebook', 'google', 'twitter', 'linkedin' and '-share' appended at the end of each. It will iterate and change the href attribute. the example above has aplaceholder href value in case it fails but will overide it if it doesn't. 

Example way of initiazing it
$S.share({
	twitter: $('.headline-large').text(),
	linkedin: $('.headline-large').text()
});

The init code takes in a object with a 'twitter' and 'linkedin' property. These to social networks require a title or string to display the description. this example takes text form the DOM to use it as the descrion.
*/

SEAGATE.share = function(params) {
    var socials = ['facebook', 'google', 'twitter', 'linkedin'],
        doc = document,
        settings = {
            twittervia: 'Seagate'
        },
        
        //pa
        params = { twitter: params.twitter || '', linkedin: params.linkedin || ''};

    var
     
    facebook = function(url){ 
        return 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    },
    
    google = function(url) {
        return 'https://plus.google.com/share?url=' + url;
    },
     
    twitter = function(name, teaser, url) {
        if(teaser){
            return 'https://twitter.com/intent/tweet?text=' + teaser + '&via=' + settings.twittervia + '&url=' + url;
        }
        
        return 'https://twitter.com/intent/tweet?text=' + name.replace(' | ', ': ') + '&via=' + settings.twittervia + '&url=' + url; 
    },
    
    linkedin = function(name, teaser, url) {
        return 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + name + '&summary=' + teaser + '&source=Seagate';    
    };
     
    //add social sharing links to he DOM   
    socials.forEach(function(val, index, self) {
        var url = window.location.href,
            share = doc.getElementById(val + '-share'), title; 
            
        switch(val) {
            case 'facebook': 
                if(share){
                   share.href = facebook(url);  
                }
                
                break;
                
            case 'google': 
                if(share){
                   share.href = google(url); 
                } 
                
                break;
                
            case 'twitter':
                if(share){
                   share.href = twitter(doc.title, params.twitter, url); 
                } 
                
                break;
                
            case 'linkedin': 
                if(share){
                   share.href = linkedin(doc.title, params.linkedin, url); 
                }
                
                break; 
        }
    }); 
    

};
