(function($S, $, undefined) {
	var resellers = {};
	
	
	resellers.get = function(country, response) {
		
		if ( typeof response !== 'function' ) return null;
		
		$.ajax({
			async: false,
			beforeSend: function( jqXHR, settings ) {},
			//url: 'data/wtb.xml',
			//TODO: Change to add country code or change country code based on country.
			url: "/ww/jsp/common/ajax.jsp?country=" + country+ "&sfdc=1",
			success: function( data ) {
				// handle successfull callback;
				var vendorsXML = $( data ).find( "DEALER" ),
					vendors = [];
					
				if ( vendorsXML.length ) {
					
					$( vendorsXML ).each( function( index ) {
						var vendor = {
							"logo": $(this).find( "DEALER_LOGO" ).text(),
							"name": $(this).find( "DEALER_NAME" ).text(),
							"url": $(this).find( "BUY_URL" ).text()
						};
						
						vendors.push( vendor );
					});

					response( null, vendors );
				} else {
					response( null, [] );
				}
				
			},
			error: function() {
				response( { success: false, msg: 'No resellers found'}, [] )
			}
		});
	};

 
 	resellers.render = function(country) {
		var 
		container = document.createElement('div'),
		tile = document.createElement('div'),
		ul = document.createElement('lu'),
		closeBtn = document.createElement('button'),
		header = document.createElement('h5'),
		li, a;
		
		closeBtn.appendChild( document.createTextNode('x') );
		closeBtn.id = 'close-resellers';
		container.className = 'resellers-lightbox';
		container.id = 'resellers-lightbox';
		container.style.display = 'none';

		
		header.appendChild( document.createTextNode( $S.localeInfo.labels.buy_from_reseller ) );
		tile.className = 'reseller-node-tile clearfix';
		ul.id = 'wtb-reseller-list';
		
		$S.resellers.get( function(err, vendors) {
			if(err) return;
			
			vendors.forEach(function(vendor, index) {
				a = createLink( vendor.url, vendor.name, '_blank');
				li = document.createElement( 'li' );
				a.appendChild( createImage(vendor.logo, vendor.name, vendor.name ) );
				li.appendChild(a);
				ul.appendChild(li);
			})
			
			tile.appendChild(header);
			tile.appendChild(ul);
			tile.appendChild(closeBtn);
			
			container.appendChild(tile);

			$('#configuratorContainer').append(container);
			
			
			$('.resellers-lightbox').on('click', function() {
				$('.resellers-lightbox').fadeOut();	
			});
			
		});
	};
	
	//attach to namespace
	$S.resellers = resellers;
	
})( SEAGATE, jQuery);