(function ($S, window) {
	'use strict';
    // cookie function from https://github.com/filamentgroup/cookie/
	//gets a cooke if only name is passed.
	//The lenght of the cooke life span is in days. If you weed a cookie for 12 hours, day would be .5
	//To remove the cookie value, you can set he expiration to 0 days and the vallue to null or ''
    function cookie( name, value, days ){
		var expires;
		
        // if value is undefined, get the cookie value
        if( value === undefined ){
            var cookiestring = "; " + window.document.cookie;
            var cookies = cookiestring.split( "; " + name + "=" );
            if ( cookies.length === 2 ){
                return cookies.pop().split( ";" ).shift();
            }
            return null;
        }
        else {
            // if value is a false boolean, we'll treat that as a delete
            if( value === false ){
                days = -1;
            }
            if ( days ) {
                var date = new Date();
                date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
                expires = "; expires="+date.toUTCString();
            }
            else {
                expires = "";
            }
            window.document.cookie = name + "=" + value + expires + "; path=/";
        }
    }//cookie
    
    //expose it
    $S.cookie = cookie;
	
	/* 
		This function gets the query parameters from the url
		It takkes a url string as a it's paremeter. 
		If it doesn't get a aprameter it will extract the paremeters from the current url.
	*/
	function getUrlVars(url){
		
    	var vars = {}, 
			hash, 
			anchors = [], 
			hashes = [];
		
		if (url) {
			hashes = url.slice(url.indexOf('?') + 1).split('&');
		} else {
			hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');	
		}
		
		
		if( hashes[0].indexOf('=') < 1 ) {
			return vars;
		}
		
		
		hashes.forEach(function(val, index) {
			hash = val.split('=');
			anchors = hash[1].split('#');
			
			//clean an anchors from the variables
			if(anchors.length > 1 ) {
				vars[hash[0]] = anchors[0];
			}else {
				vars[hash[0]] = hash[1];
			}
		});
		
		return vars;	
	}
	
	//expose it
	$S.getUrlVars = getUrlVars;
	
    function isNumber(value) {
    	return typeof value === 'number';
    } 
	
	$S.isNumber = isNumber;
	
     /**
    * animates scroll to a section on the page
    * Takes object with the following properties
    * @param {string} params.href - href string from the item clicked
    * @param {string} params.sectionId 
    * @param {number} params.speed - in milliseconds
    * @param {number} params.topOffset - in pixels
    * @return  this
    */
    function scrollTo(params) {
        
        if (!params.sectionId && !params.href) {
            return this;
        }
        
        // defaults
        params.speed = params.speed || 500;
        var sectionTop = null;
        
        if (params.sectionId) {
            sectionTop = $('#' + params.sectionId).offset().top; 
        } else if (params.href) {
            sectionTop = $(params.href).offset().top;
        } else {
            return this;
        }
        
        if (params.topOffset) {
            sectionTop -= params.topOffset;
            if (sectionTop < 0) {
                sectionTop = 0;
            }
        }
        
        $('html, body').animate({scrollTop:sectionTop}, params.speed, 'linear');
       
        return this;  
    }
		
    $S.scrollTo = scrollTo;
    
    /**
    * spinner generator, loader
    * Takes object with the following properties
    * @param {string}params.imgId 
    * @param {string} params.arrImages
    * @param {number} params.delay - in milliseconds
    * @return  this
    */
    function  swapImageOverlay(params){
        if (!params.imgId || !params.arrImages) {
            return this;
        }
        var swaps = params.arrImages.length,
            imageObjects = [],
            count = 0,
            newImg = null,  
            img = document.getElementById(params.imgId);
            
        for (var i = 0; i < params.arrImages.length; i++) {
            newImg = new Image();
            newImg.src = params.arrImages[i];
            imageObjects.push(newImg);
            
        }
        
        if (imageObjects.length === swaps) {
             var interval = window.setInterval(function () {
                if (swaps > 0) {
                    img.src = imageObjects[count].src;    
                    swaps--;
                    count++;
                } else {
                    clearInterval(interval);
                }
            }, params.delay || 800);
        }
        
        return this; 
    }
	
	$S.swapImageOverlay = swapImageOverlay;
    
    /**
    * checks if element is within window views
    * Takes object with the following properties
    * @param {string}params.elem 
    * @return boolean
    */
    function isInWindowView(params) {
        var objTop = null;
        
        if(typeof params.elem === 'undefined' || !params.elem.length){ 
            return;
        }   
             
        objTop = params.elem.offset().top; 
        
        var winTop = ( $(window).scrollTop() ),
            winHeight = $(window).height(),
            winBottom = winTop + winHeight;
         
        // chech to see if the container is with the window.
        // logic assumes the container and the slides have the same top edge or close to it
        if ( winTop < objTop   && winBottom > objTop ) {
            return true;
        } else {
            return false;
        } 
             
    }
    
	//expose to api
	$.isInWindowView = isInWindowView;
	
    
	
	/**
    * spinner generator, loader
    * Takes object with the following properties
    * @param {string} params.src 
    * @param {string} params.divId
    * @param {string} params.imgClass
    * @param {string} param.imgId  
    * @return img || none
    */
    function loadSpinner(params) {
        if (params === undefined) params = {};
        var img = new Image();
            img.src = params.src || '/www-content/web-apps/_shared/images/seagate-spinner.gif';
            img.className = params.imgClass || 'seagate-spinner';
        
        if ( params.divId) {
            $('#' +  params.divId).html(img);
            return this;
        } else {
            return img; 
        }
        
    }
	//expose to api
	$.loadSpinner = loadSpinner;

	
    /**
    * appends a css file to the head tag
    * @param {string} domId - id to add to the css link 
    * @param {string} url
    * @return none
    */
   
    function loadCSSFile(domId, url) {
        
        if (!domId || !url) {
            return;
        }
        
        var head  = document.getElementsByTagName('head')[0],
            link  = document.createElement('link');
            
        // check if the file already exist
        if ( !document.getElementById(domId) ) {
               
                if (domId) {
                    link.id   = domId;    
                }
                
                link.rel  = 'stylesheet';
                link.type = 'text/css';
                
                if (url) {
                    link.href = url;    
                }
                
                link.media = 'all';
                
                head.appendChild(link);
        }
    }
	//expose to api
	$S.loadCSSFile  = loadCSSFile;
    
	
	
    /**
    * displays a message in the provided div Id
    * if a duration parameter is provided it will fade it out after the provided time
    * Takes object with the following properties
    * @param {string} params.divId 
    * @param {string} params.msg
    * @param {number} param.duration 
    * @param {boolean} param.animate 
    * @return none
    */
    function showHideMsg(params) {
        
        // when this function is used with a duration. It will add a timeout property
        // to this helpers module, if the property is not undifined, it clears any prevous timeouts
        if (typeof SEAGATE.helpers.timeout !== undefined) {
            clearTimeout(SEAGATE.helpers.timeout);
        }
        
        // remove any previous message and hide resellers from previous choice
        $('.msg').remove();
       
        $('#vendor-container').slideUp(400, function () {
            $(this).remove();
        });
        
        // create message
        var p = document.createElement('p');
            p.className = 'msg';
            p.appendChild(document.createTextNode(params.msg));
            
        if (params.animate) {
            p.style.display = 'none';
        }
        
        // display messege and remove it after 8 seconds    
        $('#' + params.divId).append(p);
        // remove after
        
        if (params.animate) {
            $('#' + params.divId + ' .msg').slideDown(500);
        }
        
        if (params.duration) {
            // remove the msesage inside the container with the id provided
            SEAGATE.helpers.timeout = window.setTimeout(function () {
                $('#' + params.divId + ' .msg').fadeOut(500, function () {
                    $(this).remove();
                });
            }, params.duration);      
        } 
    };
	
	//expose to api
	$.showHideMsg = showHideMsg;
    
   
	//checkes if an item is an aray
    function isArray(value) {
        return value &&
            typeof value === 'object' &&
            typeof value.length === 'number' &&
            typeof value.splice === 'function' &&
            !(value.propertyIsEnumerable('length'));
    };
	
    $.isArray = isArray;
	
	//this is only being used in the business storage pages
	//It can be replaced and removed and done with jquery. There was a sepcific reason I needed this but do not recall.
	function addClassToManyDivs(searchClass, newClass) {
        // unique identifyer for css targeting
        var count = 1;
        $('.' + searchClass).each(function () { 
            $(this).addClass(newClass + '-' + count); 
            count++;
        });  
          
        return;
    };
	
	$.addClassToManyDivs = addClassToManyDivs;
	
    // Backwards copatability to the following methods. 
	//the api changed to access them without the 'helpers' object to $.somemethod but needs to still be supported. 
	
    SEAGATE.helpers = $.helpers = { 
       isNumber: isNumber, 
	   addClassToManyDivs: addClassToManyDivs,
       loadSpinner : loadSpinner,
       showHideMsg : showHideMsg,
       loadCSSFile : loadCSSFile,
       swapImageOverlay : swapImageOverlay,
       isInWindowView: isInWindowView,
       isArray: isArray,
       scrollTo: scrollTo 
    };
    
	
})(SEAGATE, window);