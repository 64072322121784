//ususage example:
/*
var backupPlus = $S.buy(ProductInfoStruct, {
	isSoftware: false,
	parent: 'jc-configurator',
	getMultiplePids: false,
	defaultSkus: {
		'STCD500102': true,
		'STDR1000102': true,
		'STDR2000103': true,
		'STDR1000101': true
	}
}, 'backup-plus-slim');
*/
(function(window, $S, undefined) {
	'use strict'
	var 
	sortedProducts = {},
	sorted = { ids: [] },
	options = {},
	isLoaded = false,
	standardProduct = '',
	
	//sorts the skus by internal name. This allows to only show the skusl that pertain to a prodcut. 
	sortProducts = function(products) {
		//products is expected to be the ProductInfoStruc 
		if(!products) return;
		var sorted = { ids: []};
		
		products.sort(function (a, b) {
            try {
                var releaseDateA = 0;
                if (typeof(a.product.releaseDate) == "object") {
                    releaseDateA = a.product.releaseDate.time || 0;
                }
                var releaseDateB = 0;
                if (typeof(b.product.releaseDate) == "object") {
                    releaseDateB = b.product.releaseDate.time || 0;
                }
                if (releaseDateB == releaseDateA) {
                    var skuReleaseDateA = Date.parse(a.releaseDate) || 0;
                    var skuReleaseDateB = Date.parse(b.releaseDate) || 0;
                    return skuReleaseDateB - skuReleaseDateA;
                } else {
                    return releaseDateB - releaseDateA;
                }
            } catch (e) {
                return 0;
            }
        });
        
        products.forEach(function(product){
			if (!sorted[product.product.id]){
				sorted[product.product.id] = {
					products: [product],
					label: product.product.label,
					id: product.product.id,
					thumb: product.imageSmallPath
				}
				
				//keep track of how many products exist on this page
				sorted.ids.push(product.product.id);
			} else {
				sorted[product.product.id].products.push(product);
			}
		});
        if (sorted.ids.length == ProductInfoStruct.releaseList[0].productList.length){
        	for (i = 0; i < sorted.ids.length; i++){
        		sorted.ids[i] = ProductInfoStruct.releaseList[0].productList[i];
        	}
        }
		return sorted;
		
	},
	
	//configures the settings for the configurator with them settings passed in the executing function.
	//these settings determine how the configurator will render.
	configure = function(settings) {
		//defaults
		var options = {
			isSoftware: false,
			parent: 'jc-configurator',
			getMultiplePids: true,
		};
		
		if( !settings && typeof settings === 'object' ) {
			return options;	
		}
		
		for( var setting in settings) {
			if(!options[setting]) {
				//if it doesn't exist add it
				options[setting] =  settings[setting];
				
			} else if (options[setting] && options[setting] !== settings[setting]) {
				//if it does exist and it's not the same as a default setting, replace it
				options[setting] =  settings[setting];
			}
		}
		
		return options;
	},
	
	//creates the html of the product image and label used to select a product
	renderControlsHTML = function(select) {
		var doc = document,
			container = doc.createElement('div'),
			option, count = sorted.ids.length,
			image, text;
		
		container.className = 'centered-text multi-product-controls clearfix';
		container.id = 'multi-product-controls';
		
		for(var i = 0; i < count; i++) {
			option = doc.createElement('a');
			
			if(select === sorted.ids[i]) {
				option.className  = 'product product-selected';
			} else {
				option.className  = 'product';
			}
			
			image = new Image();			
			image.src = sorted[sorted.ids[i]].thumb;
			//For Local TESTING
			if(location.hostname.indexOf("seagate") === -1){
				image.src = 'http://www.seagate.com' + sorted[sorted.ids[i]].thumb;
			}
		    //End TESTING
			image.alt = sorted[sorted.ids[i]].label;
			image.title = sorted[sorted.ids[i]].label;
			option.appendChild(image);
			
			option.setAttribute('data-product-id', sorted.ids[i]);
			text = doc.createElement('div');
			text.className = 'product-name';
			text.appendChild(doc.createTextNode(sorted[sorted.ids[i]].label));
			option.appendChild(text);
			//option.appendChild(doc.createTextNode(sorted[sorted.ids[i]].label));
			
			container.appendChild(option);
			
		}
		
		return container;
	},
	
	//set any events need for the selection of a product. Does not select a sku. Those events are found in 04-SEAGATE-branded-config.js under resetEventHandler funciton
	events = function() {
		$('.product').on('click touchstart', function(evt) {
			evt.preventDefault();
			
			if( $(this).hasClass('product-selected') ) {
				return;
			}
			
			//set new selected
			$('.product-selected').removeClass('product-selected');
			$(this).addClass('product-selected');
			
			//render new selector
			render($(this).attr('data-product-id'));
			
		});
	},
	
	//executes the configurator for the selected product using $S.brandedConfig.init from 04-SEAGATE-branded-config.js
	render = function (productId) {
	if(!isLoaded) { // Handle default sku passed as a URL parameter.
	    	if (typeof productSku !== 'undefined'){
	    		ProductInfoStruct.releaseList[0].modelList.forEach(function(model){
                	if( model.modelNumber === productSku){
                	  productId = model.product.id;
                	  standardProduct = productId;
                	  sorted[productId].products.forEach(function(sortedModel){
                	    if(typeof options.defaultSkus !== 'undefined'){
                	  	  if(Object.keys(options.defaultSkus).indexOf(sortedModel.modelNumber) !== -1){
                	  	    options.defaultSkus[sortedModel.modelNumber] = false;    	  		
                	  	  } 
                	  	 }
                	  });
                	}
                });
                if(typeof options.defaultSkus == 'undefined') options.defaultSkus = {};
                options.defaultSkus[productSku] = true  ;
            }
	    }
		options.productsObj.releaseList[0].modelList = sorted[productId].products;
		
		if(!isLoaded) {
			$('#configuratorContainer').prepend(renderControlsHTML(standardProduct));
			
			//set event handlers on first loadm
			events();
			isLoaded = true;
		}
		$S.brandedConfig.init(options);
	}
		
	/*
	*public API
	*Buy the executing/init function for the combine configurator.
	* @args {object} products - productInfoStruct generated in the pdp
    * @args {object} settings - an object with settings for the configurator. See 04-SEAGATE-branded-config for init documetation
    * @args {string} defaultProduct - the internal name of the product to display first. if no default, it's set to the first in the array.
	*/
	$S.buy  = function(products, settings, defaultProduct) {
		if(!products) products = ProductInfoStruct;

		sorted = sortProducts(products.releaseList[0].modelList);
		if(defaultProduct) {
			standardProduct = defaultProduct;	
		} else {
			standardProduct = sorted.ids[0];
		}
		

		//options is global
		options = configure(settings);
		//assign the products to options
		options.productsObj = products;

		//remove products form memory
		products = null;
		render(standardProduct);
		$('#configuratorContainer').css({position: 'relative'});
		
		return {
			render: render
		}
		
	}
	
})(window, SEAGATE);