// begin SEAGATE MODULE
(function( window, undefined ) {
    // Enable JS strict mode
    "use strict";
    
    // expose the SEAGATE object gobally
    window.SEAGATE = {};
    
    //Shorthand
    window.$S = window.SEAGATE;
    
    // some browser support info and expose
    SEAGATE.browser = {
        localStorage : window.localStorage ? true : false,
        geolocation : 'geolocation' in navigator,
        history : !!(window.history && history.pushState)
    }; 

    //  locale properties and such and expose
    SEAGATE.localeInfo = {
        locale : null,
        countryCode : null,
        countryNames : null,
        countryCodes : null,
        labels : null,
        ecommLocale : null,
        ecommActive : null,
        ecommLocaleObj: {},
        softwareLocale : 'en_IS',
        //RGID : null,
       // embedRGID : null,
        productSku : null,
        ciLocaleMap : null,
        seagateCIID : '285659589',
        // wtb is declared inside pdp script
        wtbLocaleMap : {
            'wtb_countries' : [{
                'wtb_country' : 'AE',
                'locale' : 'ar-em'
            }, {
                'wtb_country' : 'SG',
                'locale' : 'en-sg'
            }, {
                'wtb_country' : 'CN',
                'locale' : 'zh-cn'
            }, {
                'wtb_country' : 'US',
                'locale' : 'en-us'
            }, {
                'wtb_country' : 'TR',
                'locale' : 'tr-tr'
            }, {
                'wtb_country' : 'RU',
                'locale' : 'ru-ru'
            }, {
                'wtb_country' : 'ES',
                'locale' : 'es-es'
            }, {
                'wtb_country' : 'DE',
                'locale' : 'de-de'
            }, {
                'wtb_country' : 'FR',
                'locale' : 'fr-fr'
            }, {
                'wtb_country' : 'AU',
                'locale' : 'en-au'
            }, {
                'wtb_country' : 'BR',
                'locale' : 'pt-br'
            }, {
                'wtb_country' : 'GB',
                'locale' : 'en-gb'
            }, {
                'wtb_country' : 'IN',
                'locale' : 'en-in'
            }, {
                'wtb_country' : 'CA',
                'locale' : 'en-ca'
            }, {
                'wtb_country' : 'KR',
                'locale' : 'ko-kr'
            }, {
                'wtb_country' : 'TW',
                'locale' : 'zh-tw'
            }, {
                'wtb_country' : 'JP',
                'locale' : 'ja-jp'
            }, {
                'wtb_country' : 'IT',
                'locale' : 'it-it'
            }, {
                'wtb_country' : 'PL',
                'locale' : 'pl-pl'
            }, {
                'wtb_country' : 'ID',
                'locale' : 'id-id'
            }]
        }
    };
    
    // it the very end of the concatiated files to initlized 
    // some of the properties above.
    function setLocaleInfo() {
        var countryNames = document.getElementById('countryNames'),
            countryCodes = document.getElementById('countryCodes'),
            localeInfo = SEAGATE.localeInfo;
            
        // used in pdp's only for fiding resellers
        if (countryNames && countryCodes) {
            localeInfo.countryNames = countryNames.value.split('|');
            localeInfo.countryCodes = countryCodes.value.split('|'); 
        }
        
        // product sku is extractedf rom the url in the pdp if not found it not set
        localeInfo.productSku =  typeof productSku !== 'undefined' ? productSku : null;
        localeInfo.locale = typeof rcLocaleJS !== 'undefined' ? rcLocaleJS : '';
        localeInfo.countryCode = typeof UserCountryCode !== 'undefined' ? UserCountryCode : 'US';
        
       // labels used throught the site set in source code
        localeInfo.labels =  typeof labels !== 'undefined' ? labels : {
            preorder: 'Preorder',
            instock: 'In Stock',
            backorder: 'Backorder',
            outofstock: 'Out of Stock',
            add_to_cart: 'Add to Cart',
            buy_from_reseller: 'Buy from a reseller'
        };  
         
       
 
        // channel intellengece map, will eventually go away.
        localeInfo.ciLocaleMap = typeof ciLocaleMap !== 'undefined' ? ciLocaleMap : null;
        
        // ecom locale is a global function in IT's javascript file 
        
        
        if (typeof ecommLocaleMap !== 'undefined') {
            var thisEcommLoacle = "";
            for (var i = 0; i < ecommLocaleMap.ecommLocalesList.length; i++)  {
                if (ecommLocaleMap.ecommLocalesList[i].ecommLocale === thisEcommLoacle ) {
                    localeInfo.ecommLocaleObj = ecommLocaleMap.ecommLocalesList[i];
                    localeInfo.ecommLocale = ecommLocaleMap.ecommLocalesList[i].ecommLocale;
                    localeInfo.ecommActive = ecommLocaleMap.ecommLocalesList[i].ecommActive;
                    break;
                }
            } 
        }else {
            //US ecom
            if(rcLocaleJS === 'en-us'){
                localeInfo.ecommLocale = 'en-us';
                
                localeInfo.ecommLocaleObj = {
                    "ecommLocale" : "en-us",
                    "ecommActive" : true,
                    "seaLocale" : "en-us",
                    "drLocale" : "en_US",
                    "currency" : "USD",
                    "nonpdp" : true
                }; 
            }
            
            //Canada ecom
            if(rcLocaleJS === 'en-ca'){
                localeInfo.ecommLocale = 'en-us';
                
                localeInfo.ecommLocaleObj = {
                    "ecommLocale":"en-ca",
                    "ecommActive" : true,
                    "seaLocale":"en-ca",
                    "drLocale":"en_CA",
                    "currency":"CAD",
                    "nonpdp" : true
                }; 
            }
        }
        
        // rgid and embed rgid are used for resellers and where to buy
        //localeInfo.RGID = this.commerce.getRGID(localeInfo.locale, 'xml'); 
        //localeInfo.embedRGID = this.commerce.getRGID(localeInfo.locale, 'embed'); 
    
    };

    // expose the interface
    SEAGATE.setLocaleInfo = setLocaleInfo;
    
}( this ));
