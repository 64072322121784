SEAGATE.brandedConfig = (function(window, undefined) {
    'use strict';
    var standardConfigurator = false;
    var siteLabels = null,
        config = {
            defaultSkus: {},
            isSoftware: '',
            parent: 'jc-configurator',
            getMultiplePids: true,
            sortFeature: ''
        },
        localProductInfoStruct = null,
        isPriceSpiderLocale = '',
        isPriceSpiderPage = '',
	    isPriceSpider = '',
	    forceAttributesDisplay = '',
	    isExternal = '',
	    priceSpiderCountry = '',
	    wtb_categories = '',
	    comparisonEnabled = false,
        ecomInfo = '',
        ecomActive = '',
        drLocale = '',
        currency = '',
        sortedProducts = {},
        numColumns = 0,
        contWidth = $(window).width() - 50,
        smallImage = '',
        hasColors =  false,
        ecomInfoObjs = [],
        promises = [],
        attributes = [],
        promiseInterval = null,
		
        stringValues = {
			row: 'row-fluid',
            openConfig: 'open-config',
            columnId: 'open-config-column-',
            attrList: 'text-attr-list',
            attrClass: 'text-attribute',
            attrCurrentClass: 'text-attribute-current',
            drDynamicData: 'dr-dynamic-data',
            colorList: 'open-config-color-list',
            mColorList: 'mobile-config-color',
            colorConfig: 'open-config-color',
            colorConfigCurrent: 'open-config-color-current',
            configPrice: 'open-config-price',
            salesPrice: 'open-config-sales-price',
            crossedPrice: 'crossed-price',
            stockStatus: 'open-config-stock-status',
            configSpinner: 'open-config-spinner',
            configCta: 'open-config-cta',
            resellersContainer: 'reseller-link'             
        },
        
        defaults = {
            sortBy: 'capacity_ts_master_feat'
        };
     
	//resets the configuration the the init method is called more than once. 
	//this currently only happens in combined pdps
	 var reset = function() {
		 sortedProducts = {};
		 numColumns = 0;
		 ecomInfoObjs = [];
		 attributes = [];
	 };
     /*
     * initilizes the configurator
     * 
     * @args {boolean} args.isSoftware - use for software.
     * @args {boolean} args.debug - set's the config ot debug mode
     * @args {string} args.parent - container to append to 
     * @args {boolean} args.getMultiplePids - get more than one pid at a time
     * @args {string} args.sortFeature - feature to sort skus by, select from prodStruct
     * @args {obj} args.defaultSkus - the default skus in obj format to select if provided, sku should be the key, true the val
     * @args {obj} args.content - cotains a string or html of content to use next to a one column configurator
     * @args {boolean} args.showAsSwatches - should show colors of drives or solid color swatches 
     * @args {boolean} args.showLabels  - decides if the text labels for features should be shown
     * @args {boolean} args.productsObj  - product object, option to pass a different variable name

     * * */ 
	//TODO: abstact into other functions some of the work INIT is doing
    var init = function(args) {
		
        var 
		i, 
		releaseList, 
		attrs, 
		products = [], 
		modelList = [], 
		feature, 
		numProducts;
		
        localProductInfoStruct =  args.productsObj || {};
		
		//reset configuration 
		reset();
		//preload loading spinner
		imageSpinner(true);
        
        if(args.productsObj){
           localProductInfoStruct =  args.productsObj;
        }else{
            localProductInfoStruct = typeof ProductInfoStruct !== 'undefined' ? ProductInfoStruct : undefined;
        }
       
        if (typeof localProductInfoStruct === 'undefined') { 
            //do do anything if there is no product struc object.
            console.log('No product information found');
            return;
        }
     
                 
        // a few variables to use in this module  
        ecomInfo = $S.localeInfo.ecommLocaleObj;
        ecomActive = false;
        isPriceSpiderLocale = SUPPORTED_PS_LOCALE.indexOf(rcLocaleJS) != -1;
        isPriceSpiderPage = localProductInfoStruct.releaseList[0].priceSpiderEnabled;
        forceAttributesDisplay = localProductInfoStruct.releaseList[0].forceAttributesDisplay;
        isExternal = localProductInfoStruct.releaseList[0].external;
        wtb_categories = localProductInfoStruct.releaseList[0].categories;
	    isPriceSpider = isPriceSpiderLocale && isPriceSpiderPage;
        comparisonEnabled = localProductInfoStruct.releaseList[0].blocks.comparisonBlock;
        // args passed by user, double or add to defualt
		//TODO: need some validation here for the right type
        config.isSoftware = args.isSoftware || false;
        config.parent = args.parent || config.parent;
        config.defaultSkus = args.defaultSkus || false;
        config.getMultiplePids = args.getMultiplePids;
        config.sortFeature = args.sortFeature || defaults.sortBy;
        config.largeimage = args.largeimage || '';
        config.content = args.content || {image: false, html: false};
		//default to swatches for now
        config.showAsSwatches = true, //args.showAsSwatches || false; 
        config.showLabels = args.showLabels || false;
        config.debug = args.debug || false;
        config.enableBH = args.enableBH || false;
        config.pmidMap = args.pmidMap || false;
        
        // handle sku param in URL
        if (typeof productSku !== 'undefined' && Object.keys(config.defaultSkus).length < 2){// not multi defaults product
            args.defaultSkus = {};
            args.defaultSkus[productSku] = true;
            config.defaultSkus = args.defaultSkus;
        }
        
		
        
        //dr locale code and currency
        //if it's not software  set ecom locale to the current currency and 
        // locale informaiton provided in the page source.
        if(!config.isSoftware) {
            currency = ecomInfo.currency;
            drLocale = ecomInfo.drLocale;
        }else {
            currency = 'USD';
            //if the ecom dr locale is anthing outside of the us
            //set it to the 'en_IS' locale.  
            if(ecomInfo.drLocale !== 'en_US') {
                drLocale = SEAGATE.localeInfo.softwareLocale;     
            }else{
                drLocale = 'en_US';
            }
        }
        
        //chache values for iteration 
        products = localProductInfoStruct.releaseList[0];
        modelList = products.modelList;
        
        modelList.sort(function (a, b) {
            try {
                var releaseDateA = 0;
                if (typeof(a.product.releaseDate) == "object") {
                    releaseDateA = a.product.releaseDate.time || 0;
                }
                var releaseDateB = 0;
                if (typeof(b.product.releaseDate) == "object") {
                    releaseDateB = b.product.releaseDate.time || 0;
                }
                if (releaseDateB == releaseDateA) {
                    var skuReleaseDateA = Date.parse(a.releaseDate) || 0;
                    var skuReleaseDateB = Date.parse(b.releaseDate) || 0;
                    return skuReleaseDateB - skuReleaseDateA;
                } else {
                    return releaseDateB - releaseDateA;
                }
            } catch (e) {
                return 0;
            }
        });
        
        attrs = products.attributes;
        numProducts = products.modelList.length;
        
        // cache local copy of labels
        siteLabels = SEAGATE.localeInfo.labels;
        
        if (products.colorsList) {
            hasColors = true;
        }
        
		// filter features
		attrs.forEach(function(element, index, array) {
			if(element.name !== config.sortFeature) {
				attributes.push(element);
			}
		});
		
		//if(isPriceSpider){
		 //    priceSpiderCountry = $('#countryDefault').val() !== undefined ? $('#countryDefault').val() : "US";
         //    $('head').append('<meta name="ps-account" content="468" />');
        //	 $('head').append('<meta name="ps-config" content="5630ff00f1e12ae7869915c3" />');
        //	 $('head').append('<meta name="ps-country" content="' + priceSpiderCountry + '" />');
		//}
		//sort the products into columns a specified feature, capacity or the frist one.
		for (i = 0; i < numProducts; i++) {

			if(modelList[i][config.sortFeature]){
				//set feature to sort by
				feature = modelList[i][config.sortFeature][config.sortFeature + '_name'];
			} else if( modelList[i][defaults.sortBy] ){
				//if it doesn't exist, sort by capacity 
				feature = modelList[i][defaults.sortBy][defaults.sortBy + '_name'];
			} else {
				//TODO: set to the first feature to sort by if capcity or pass feature do not exist
				//Capcity would not exist when it is software.
			}

			// if defaults skus and this sku is a match and not getting all skus in one request
			// get dr data for this sku and add to ecom object.
			if( config.defaultSkus && config.defaultSkus[modelList[i].modelNumber] && !config.getMultiplePids ) {
				addToEcomInfoObjs(modelList[i]);    
			} else {
				//else just add to ecom object array
				addToEcomInfoObjs(modelList[i]);
			}

			// add sku object to soreted array object
			if (!sortedProducts[feature]) {
				sortedProducts[feature] = {
					label: feature,
					products: [modelList[i]]
				};
				// count columns
				numColumns++;

			} else {
				sortedProducts[feature].products.push(modelList[i]);    
			}
		}//end forloop
		
		if(hasColors){
			sortedProducts = sortColors(sortedProducts);
		}
        
        //get dr info if no default skus have been provided
        if (config.getMultiplePids) {
            getDrData(modelList);
            
        } else if(!config.getMultiplePids && !config.defaultSkus) {
            //else if not getting multiple and no default skus provided 
            // get ecom data for the first product of each column
            for (var product in sortedProducts) {
                getDrData([sortedProducts[product].products[0]]);
            }
            
        } //end if get multiple-pids

        // save a copy to the small image path for the product family
        smallImage = products.imageSmallPath;
        
        buildConfigHTML();
        // run promise loop
        
		//adds a gray bg to versions where there is more than one column in the config config. 
        if(numColumns > 1) {
            var configStyleObj = document.getElementById('configuratorContainer');
             
            //configStyleObj.style.backgroundColor = "#f5f5f5";
            configStyleObj.style.borderBottom = "1px solid #ebebeb";
            configStyleObj.style.paddingTop = "40px";
            configStyleObj.style.marginTop = "-38px";
        }
        
        //initate loop that populates price/stock status info
        promiseLoop();
    }; //init
    
	//create a object with the sku ecom info.
    var addToEcomInfoObjs =  function (sku, requested) {
        ecomInfoObjs.push({
            domObj : null,
            data : null,
            requested: false,
            pid : sku.drProductId,
            fulfilled : false,
            sku : sku,
            returned : false,
            type : 'drinfo'
        }); 
    };
	
	//the promise loop fullfils all the UI calls with data coming from digital rivers. 
	//when a user selects a sku. It re-renders the product emcom info and buy button.
    var promiseLoop = function () { 
        var 
		height= 0,
		
        //Ojbect literal replacing a switch statement
        types = {
            'drinfo': function(info) {
                $(info.domObj).html(createDrDataHtml(info.data, info.sku)); 
                if(typeof(PriceSpider.rebind) == 'undefined'){
			yepnope({
			  test : true ,
			  yep  : '//cdn.pricespider.com/1/lib/ps-widget.js',
		      callback: function () {

            		PriceSpider.rebind();           

			  }
			});
	    }else{
  
            PriceSpider.rebind();           
		}    
            }
        };
		
        promiseInterval = setInterval(function () { 
                              
            for (var i = 0; i < promises.length; i++) {
                if (promises[i].returned) {
                    //ececute correct promose
                    types[promises[i].type](promises[i]);
                    
                    promises[i].fulfilled = true;
                    // remove it from the array
                    promises.splice(i, 1); 
					
                }   
            }//for loop
			
			
        }, 500);
    };
    
    // check for a match in skus with dr data with 
    var getSkuEcomData = function(sku, containerObj) {
        
        var found = false;
       
        for (var i = 0; i < ecomInfoObjs.length; i++) {
         
            if (sku === ecomInfoObjs[i].sku.modelNumber) {
                // attache the DOM object it will be appened too.
                ecomInfoObjs[i].domObj = containerObj;
                ecomInfoObjs[i].fulfilled = false;
                
                // if dr data exist the found to true, add to promise loop. 
                if(ecomInfoObjs[i].data !== null) {
                    promises.push(ecomInfoObjs[i]);
                    found = true; 
                }
                
                break;
            }
        }
        
        // if dr data does not exist, request it from digital river. 
        if (!found) {
            getDrData([getModelObject(sku)]); 
        }
        
    };
    
	//starts the process builing the UI for the config. This function is called in init();
    var buildConfigHTML = function() {
        
        var doc = document,
            wrapper = doc.createElement('ul'), 
            count = 1, bucket = null;
           
        wrapper.className = stringValues.openConfig + ' ' + stringValues.row;
        wrapper.id = stringValues.openConfig;
        
        if ($(window).width() < 768) {
            $(wrapper).width(contWidth * numColumns);
        }
		
		wrapper.innerHTML = '';
		
        for (var product in sortedProducts) {
            var prodDiv = buildColumnHtml(product);
			
            prodDiv.id = stringValues.columnId + count++;
            wrapper.appendChild(prodDiv);
        }
        
		//hide "select a model title"
        //bucket = doc.createElement('h5');
        //bucket.className = 'centered-text';
        //bucket.innerHTML = siteLabels.select_model;
        //bucket.style.display = 'none';
		
        $( '#' + config.parent ).html(bucket).append(wrapper).css({opacity: 0});
		$( '#' + config.parent ).animate({ opacity: 1}, 1000);
        
        if(numColumns > 1) {
            wrapper = doc.createElement('a');
            wrapper.id = 'config-swipe-next';
            $('#' + config.parent ).append(wrapper);
            
            wrapper = doc.createElement('a');
            wrapper.style.display = 'none';
            wrapper.id = 'config-swipe-prev';
            $('#' + config.parent ).append(wrapper);
			
        } else {
            if(config.content.image){
                wrapper.appendChild(buildImageColumn(config.content.image, ''));
            }else if(config.content.html){
                //append html string passed in
                $(wrapper).append($('<li class="span6 config-html-column">' + config.content.html + '</li>'));
            }else if(config.largeimage){
                //for backwards copatablity with first implemintaiton
                wrapper.appendChild(buildImageColumn(config.largeimage, ''));
            }
        }
        
		//build where to buy reseller list
	//	if( !document.getElementById('resellers-lightbox') ) {
	//		whereToBuyList();  
	//	}
		
		resetEventHandlers();
    };
	
	//builds a select element with country titles for the where to buy lightbox
	/*var renderCountryList = function(country) {
		var
		countryCodes = document.getElementById('countryCodes'),
		countryNames = document.getElementById('countryNames'),
		select = document.createElement('select'), 
		option;
		
		
		if(!countryCodes.value || !countryNames.value) {
			return;
		}
		
		select.id = 'reseller-country-codes';
		select.className = 'reseller-country-codes';
		countryCodes = countryCodes.value.split('|');
		countryNames = countryNames.value.split('|');
		
		countryCodes.forEach(function(code, index) {
			option = document.createElement('option');
			option.value = code;
			option.appendChild( document.createTextNode( countryNames[index] ));
			
			if( code === UserCountryCode ) {
				$(option).attr('selected', 'selected');
			}
			
			select.appendChild(option);
		});
		
		return select;
	};*/
	
	//in the multi-product config, wtb gets redrawn when the user changes product
	/*var whereToBuyList = function() {
		var 
		container = document.createElement('div'),
		tile = document.createElement('div'),
		closeBtn = document.createElement('button'),
		header = document.createElement('h5'),
		wtb = document.createElement('div'),
		//UserCountryCode is global declared IT code
		country = UserCountryCode || 'US';
		
		closeBtn.appendChild( document.createTextNode('x') );
		closeBtn.className = 'close-resellers';
		container.className = 'resellers-lightbox';
		container.id = 'resellers-lightbox';
		container.style.display = 'none';
		
		header.appendChild( document.createTextNode( $S.localeInfo.labels.buy_from_reseller ) );
		
		tile.className = 'reseller-node-tile clearfix';
		wtb.id = 'wtb-resellers';
		
		$S.resellers.get(country, function(err, vendors) {
			//TODO: if there was an error display a message to the user
			if(err) {
				//hide links to show resellers ajax returns an error
				$('.show-resellers').hide();
				return;
			}
			
			var list = renderResellerList(vendors);
			
			tile.appendChild(header);
			tile.appendChild( renderCountryList(country) );
			wtb.appendChild(list);
			tile.appendChild(wtb);
			tile.appendChild(closeBtn);
			
			container.appendChild(tile);

			$('#configuratorContainer').append(container);
			
			//unbind events in case a redraw happened 
			$('#reseller-country-codes').unbind('change');
			$('.resellers-lightbox').unbind('click');
			
			$('.resellers-lightbox').on('click', function(e) {
				if($(e.target).hasClass('resellers-lightbox') 
				   || $(e.target).hasClass('close-resellers') ) {
					$('.resellers-lightbox').fadeOut();
				}
			});
			
			$('#reseller-country-codes').on('change', function() {
				//get new list of vendors
				var country = $(this).val();
				$('#wtb-resellers').html(imageSpinner());
					
				$S.resellers.get(country, function(err, vendors) {
					//TODO: display error to user, use extract into 
					//new function to resuse bellow
					if(err) return;
					var newVendor = renderResellerList(vendors);
					newVendor.style.display = 'none';

					$('#wtb-resellers img').fadeOut(function() {
						$(this).remove();
						$('#wtb-resellers').html(newVendor);
						$('#wtb-reseller-list').fadeIn();
					});
				});
			});
			
		});
	}; */// whereToBuyList
	
	/*var renderResellerList = function(vendors) {
		var 
		msg = labels.not_available_resellers || "We're adding more resellers all the time, try another model or check back soon.", 
		results; 
		
		if(!vendors || !vendors.length) {
			results = document.createElement('p');
			results.id = 'wtb-reseller-list';
			results.appendChild( document.createTextNode(msg));
			return results;
		}
	
		var results = document.createElement('ul'), li, a;
		
		results.id = 'wtb-reseller-list';
	
		vendors.forEach(function(vendor, index) {
			a = createLink( vendor.url, vendor.name, '_blank');
			li = document.createElement( 'li' );
			a.appendChild( createImage(vendor.logo, vendor.name, vendor.name ) );
			li.appendChild(a);
			results.appendChild(li);
		})
		
		return results;
	};*/
	
	//creates the link for the where to buy link and returns it. 
	/*var renderResellersCTA = function() {
		var resellersLink = document.createElement('div');
		resellersLink.className = "show-resellers";
		resellersLink.appendChild(document.createTextNode(labels.buy_from_reseller || 'Buy from a reseller'));
	
		return resellersLink;
		
	};

	*/
	
	// creates a generic link, this could be moved to functions, but lives here now and it is used at least in one place.
	var createLink = function(url, title, target, anchor) {
		
		var link = document.createElement('a');
		
		// TODO: Add logic to add anchor option (#anchor) as well as parameters;
		if ( url) {
			link.href = url;
			link.title = title || '';
			link.target = target || '';
		}
		
		return link;
	};
	
	//creates an image and returns it. can be used to precaching images. 
	var createImage = function(src, alt, title) {
		var img = document.createElement('img');
		
		img.src = src;
		img.title = title;
		img.alt = alt;
		
		return img;
	};
    
	//takes a sku number as a string and gets the ecommerce object martching it from the cached ecom objects.
    var getEcomObj = function( sku ) {
        var objs = ecomInfoObjs;
		
        if ( !sku ) return;
        
        for( var i = 0; i < objs.length; i++ ) {
            if ( objs[i].sku.modelNumber === sku ) {
                return objs[i];
            } 
        }    
    };
    
    /*
    *build the column with an image when there is  
    * only one column in the config
    */
    var buildImageColumn = function(src, alt){
        if ( !src ) return;
        
        var column = document.createElement( 'li' ),
            image = new Image();
        
        //build column
        column.className = 'span6 image-column clearfix hidden-phone';
        column.style.textAlign = 'left';
        
        image.src = src; 
        image.alt = alt;
        column.appendChild( image );
        return column;
    };
    
    // creates and returns html for column titles
    var createColumnTitle = function( label, defaultproduct ) {
        var 
		doc = document,
		title = doc.createElement( 'h4' );
        //append color to title
        // capacity tittle
        //append color to title
        if ( hasColors ) {
            title.innerHTML = label + '<span class="color-label"> - ' + defaultproduct.colors_master_parent_feat.colors_master_parent_feat_name + '</span>';
        } else {
            title.appendChild( doc.createTextNode(label) );
        }
        
        return title;
    };
    
	//creats html with an image for products that only have one column. See Wireless Mobile page with a picture next to the configurator
    var createColumnImage = function(defaultSku){
        var
        container = document.createElement('div'),
        image = new Image();
        
        container.className = stringValues.row + ' selected-sku-img';
        image.alt = defaultSku.modelName;
        
        if(config.debug){
            // selected item image path
            if (defaultSku.imageSmallPath) {
                image.src = 'http://www.seagate.com' + defaultSku.imageSmallPath;
                container.appendChild(image);
            } else if(smallImage) {
                image.src = 'http://www.seagate.com' + smallImage;
                container.appendChild(image);
            }    
        }else{
            // selected item image path
            if (defaultSku.imageSmallPath) {
                image.src = defaultSku.imageSmallPath;
                container.appendChild(image);
            } else if(smallImage) {
                image.src = smallImage;
                container.appendChild(image);
            }
        }
        
        return container;
    }; //end createColumnImage
    
	//builds the text base attribute list for a column. This is the color swatches or boxes with strings seen in business storage or products with colors. 
    var buildAttributeList = function(attribute, products, defaultSku){
        //build feature list
        var doc = document,
            attrList = doc.createElement('ul'),
            attr, feature, label, 
            isColor = attribute.name === 'colors_master_parent_feat',
            productNum = products.length, labels = [],
            title = doc.createElement('p'),
            hasSwatches = false;
        
        if(config.showLabels){
            title.appendChild(doc.createTextNode(attribute.title));
            title.className = 'feature-title';
            attrList.appendChild(title);
            
        }
		
        if((productNum > 1 || forceAttributesDisplay)  && !isColor) {
            var title = doc.createElement('div');
            title.className = 'feature-list-label';
            title.appendChild(doc.createTextNode(attribute.title));
            attrList.appendChild(title);
        }
        
        if(isColor) {
            attrList.className = stringValues.colorList + ' ' + stringValues.mColorList + ' clearfix'; 
        }else {
           attrList.className = stringValues.attrList + ' clearfix';
        }
        
        
        if(productNum > 1 || forceAttributesDisplay){   
            for (var i = 0; i < products.length; i++) {
                attr = products[i][attribute.name][attribute.subitem];
                feature = doc.createElement('li');
                
                //add appropriate class
                if (!isColor) {
                    //attributes are text based and not unique labels
                    //they need to be filtered
                    if(labels.indexOf(attr) ===  -1){
                        labels.push(attr);
                    
                        label = doc.createElement('span');
                        label.innerHTML = attr;
                        
                        //image source to replace feature image
                        if(config.debug) {
                            $(label).attr('data-image-src', 'http://www.seagate.com' + products[i].imageSmallPath);
                        }else{
                            $(label).attr('data-image-src', products[i].imageSmallPath);
                        }
                        
                        //attach model name as data attr
                        $(label).attr('data-model-title', products[i].modelName);

                        if (defaultSku.modelNumber === products[i].modelNumber) {
                            feature.className = stringValues.attrClass + ' ' + stringValues.attrCurrentClass;
                        } else {
                            feature.className = stringValues.attrClass;
                        }
                        
                        feature.appendChild(label);
                    }else{
                       feature.style.display = 'none'; 
                    }
                }else {
                    //products with color varitions
                    // product image;
                    if(config.showAsSwatches){
                        hasSwatches = true;
                        label = doc.createElement('div');
                        label.className = "color-swatch drive-color";
                        label.style.backgroundColor = products[i].colorCode;
                        label.alt = products[i].modelName;
                        label.title = products[i].modelName;
                        
                        //image source to replace feature image
                        if(config.debug) {
                            $(label).attr('data-image-src', 'http://www.seagate.com' + products[i].imageSmallPath);
                        }else{
                            $(label).attr('data-image-src', products[i].imageSmallPath);
                        }

                    }else{
                        label = new Image();
                        if(config.debug) {
                            label.src = 'http://www.seagate.com' + products[i].imageSmallPath;
                            $(label).attr('data-image-src', 'http://www.seagate.com' + products[i].imageSmallPath);
                        }else{
                            label.src = products[i].imageSmallPath;
                            $(label).attr('data-image-src', products[i].imageSmallPath);
                        }
                        
                        label.className = 'drive-color';
                        label.title = products[i].modelName;
                        label.alt = products[i].modelName;
                        
                        //image source to replace feature image
                    }
                    
                    
                    if (defaultSku.modelNumber === products[i].modelNumber) {
                        feature.className = stringValues.colorConfig + ' ' + stringValues.colorConfigCurrent;
                    } else {
                        feature.className = stringValues.colorConfig;
                    }
                    
                    feature.appendChild(label);
                }
    
                
                feature.title = attr;
                
                attrList.appendChild(feature);
                $(feature).attr('data-model', products[i].modelNumber);
            }//for loop
           // if (hasSwatches) {
           // 	attrList.innerHTML = sortColors(attrList, SEAGATE);
          //  }
            return  attrList;
        }
        
        return doc.createElement('span');
       
    };//buildAttributeList

	//inits the build build of an individual column for the configurator. Runs for in a loop for the number of columns
    var buildColumnHtml = function(productLabel, colNum) {
        var doc = document, 
            column = doc.createElement('li'), 
            bucket2 = null,
            bucket = null, 
            drDataElem = doc.createElement('div'), 
            width = null,
            attr, 
            attrList, 
            isColor, 
            hasColor = hasColors, 
            attrName, 
            colProducts = sortedProducts[productLabel].products,
            ressellerSku = null, 
            defaultSkuMatch = false,
            numProducts = ecomInfoObjs.length - 1;
            
        if ($(window).width() < 768) {
            $(column).width(contWidth -20);
        }
        
        //find the deafault product for each column
        for (var i = 0; i <= numProducts; i++) {
            // first check if the sku is in the defaults object
            if(config.defaultSkus && config.defaultSkus[ecomInfoObjs[i].sku.modelNumber] ){
                // if it is check if this model number is in this list of products
                defaultSkuMatch = getModelObject(ecomInfoObjs[i].sku.modelNumber, colProducts);
            }else if(!config.defaultSkus && colProducts[0].modelNumber === ecomInfoObjs[i].sku.modelNumber){
                //select the first sku of the group if no default was added.
                defaultSkuMatch = colProducts[0];
            }
            
            //map the promise object to the selected sku
            if(ecomInfoObjs[i].sku.modelNumber === defaultSkuMatch.modelNumber) {
                ecomInfoObjs[i].domObj = drDataElem;    
            
                //if ecom info hasn't been requesting, do it now.
                //fallback in case the defalut skus do not match any of the actual product skus.
                if(!ecomInfoObjs[i].requested){
                    getDrData([defaultSkuMatch]); 
                    ecomInfoObjs[i].requested = true; 
                }
                
                break;  
            }
        }//end for loop
        
        // fail safe in case the default skus provided do not match antyhing
        // will default to show the first item on the list of each column.
        if(!defaultSkuMatch) {
            defaultSkuMatch = colProducts[0];
            
            var obj = getEcomObj(defaultSkuMatch.modelNumber);
            getDrData([defaultSkuMatch]);
            obj.domObj = drDataElem;
        }
        
        
        //add class to element
        drDataElem.className = stringValues.drDynamicData;
        
        switch( numColumns ) {
            case 1:
				if(config.largeimage || config.content.image || config.content.html) {
					column.className = 'span6 capacity-column clearfix';
				} else {
					column.className = 'capacity-column clearfix';
				}
				
				break;
            
            case 2: 
				column.className = 'span6 capacity-column clearfix';
            	break;
            
            case 3: 
				column.className = 'span4 capacity-column clearfix';
            	break;
            
            case 4: 
				column.className = 'span3 capacity-column clearfix';
            	break;
            
            case 5: 
				column.className = 'span3 capacity-column column-with-scroll clearfix';
            	break;
        }
         
        //append column title
        column.appendChild(createColumnTitle(productLabel, defaultSkuMatch));
        
        //append small product image
        column.appendChild(createColumnImage(defaultSkuMatch));
        
        //create feature list
        if(colProducts.length > 1 || forceAttributesDisplay){
           attributes.forEach(function(element, index, array) {
                column.appendChild( buildAttributeList(element, colProducts, defaultSkuMatch));
            }); 
        }
        
        bucket = doc.createElement('p');
        bucket.className = stringValues.configSpinner;
     	
        // attach a spinner
        bucket.appendChild(imageSpinner());
		
        // append spinner
        drDataElem.appendChild(bucket);
        
        // append dr data container
        column.appendChild(drDataElem);
      
       // if ( !config.isSoftware ) {
            // append resellers link;
        //    column.appendChild(renderResellersCTA()); 
        //}
        
        return column;    
    };  //end buildColumnHtml
    
	
	//creates an image spinner when reloading content, see configurator for example
	var imageSpinner = function(preload) {
		var image = new Image();
        //create spinner to resuse across the app
        if(config.debug){
            image.src = 'http://www.seagate.com/www-content/web-apps/_shared/images/seagate-spinner.gif';
        }else{
            image.src = '/www-content/web-apps/_shared/images/seagate-spinner.gif';
        }
		
        image.alt = 'Loading...';
		
		return !preload ? image : false;
	}
	
	var buildWtbResellers = function(wtb_categories) {
	     
		var countryCodeStr = $("#countryCodes").val();
		var countryCode = countryCodeStr.split('|');
		var countryStr = $("#countryNames").val();
		var countryName = countryStr.split('|');
        var country_default = $("#countryDefault").val();
        var wtb_categories = ProductInfoStruct.releaseList[0].categories;
        
        $('#wtbModal').remove();
        if(country_default == "EM") country_default = "AE";
	
	     var wtb_language = rcLocaleJS.substring(0, 2) + rcLocaleJS.slice(2).toUpperCase();
	     var wtbHtml = "";
	     var wtbLinkHtml = "";
         if($('#wtbJCModal').length === 0){
          
			 wtbHtml += "<div id=\"wtbJCModal\" class=\"modal hide fade in\">\n";
			 wtbHtml += "	<div class=\"modal-body configurator-wtb\">\n";
			 wtbHtml += "		<button data-dismiss=\"modal\" class=\"nav-footer-close close\">&#xD7;</button> \n";
			 wtbHtml += "		<div data-dismiss=\"modal\" class=\"nav-footer-close \">\n";
			 wtbHtml += "			<div class=\"nav-footer-lt\">\n";
			 wtbHtml += "			</div>\n";
			 wtbHtml += "			<button class=\"nav-footer-close\">Back</button> \n";
			 wtbHtml += "		</div>\n";
			 
			 
		    wtbHtml += "<form class=\"regionFrm clearfix\" id=\"form1\"> \n";
			wtbHtml += "   <fieldset> \n";
			wtbHtml += "     <label for=\"regionSelector\">Select your country:</label> \n";
			wtbHtml += "     <select class=\"sel\" id=\"regionSelector\" name=\"regionSelector\" onchange=\"getWTBResellers(this.value);\"> \n";
				for (var u = 0; u < countryName.length; u++) {
					if (country_default == countryCode[u]) {
						wtbHtml += "<option value=" + countryCode[u] + " selected=\"selected\">" + countryName[u] + "</option>\n";
					} else {
						wtbHtml += "<option value=" + countryCode[u] + ">" + countryName[u] + "</option>\n";
					}
    			}
			wtbHtml += "     </select> \n";
			wtbHtml += " </fieldset> \n";
			wtbHtml += "</form> \n";
			
			
			 wtbHtml += "		<div id=\"wtbContent\"/></div>\n";
			 wtbHtml += "	</div>\n";
			 wtbHtml += "</div>\n";
			  
			 $('body').append(wtbHtml);
		 }
		


		 $('#wtbContent').load('/ww/resellersData?language=' + wtb_language + '&country=' + country_default + '&categories=' + wtb_categories + '&brand=' + brand);
         if(wtb_categories == "" || wtb_categories == null){
				wtbLinkHtml = '<div class="wtb-widget"><a class="btn btn-primary btn-small" href="http://wheretobuy.'+ brand +'.com">' + labels.buy_from_reseller + '</a></div>\n'; 
		 } else if (config.enableBH) {
                /*
                wtbLinkHtml = '<div class="bh"><img src="/www-content/web-apps/_shared/images/b-and-h-logo.png"> <a class="btn btn-primary btn-small" href="http://redir.pricespider.com/redirect/?cid=5630ff00f1e12ae7869915c3&iid=57eeddfc-f900-462f-a138-6a1ffdccbe92&v=1.5.3&pmid=' + config.pmidMap[0] + '&widgetType=widget&t=576765&n=0&_=1483745285529" data-toggle="modal">BUY NOW</a></div><div class="wtb-widget"><a href="#wtbJCModal" data-toggle="modal">View More Resellers</a></div>';
                */
                wtbLinkHtml = '<div class="wtb-widget bh"><a href="#wtbJCModal" data-toggle="modal">View More Resellers</a></div>';
        } else {
                wtbLinkHtml = '<div class="wtb-widget"><a class="btn btn-primary btn-small" href="#wtbJCModal" data-toggle="modal">' + labels.buy_from_reseller + '</a></div>'; 
         }
	     
	     return wtbLinkHtml;
	     $('.wtb-widget a').click( function(e) {
	            getResellers(currentCountry.toUpperCase());
         });
	}

	//creates stock status, buy button and offers in each column
    var createDrDataHtml = function(data, sku) {
        if($('#NotifyMe')) {
			$('#NotifyMe').remove();	
		}
		
        var bucket = null, bucket2 = null, string = null, 
        container = null, stockStatus = '', divBucket = null,
        doc = document, addNotifyMe = false;
        
        container = doc.createElement('div');
       
        // stock status
        bucket = doc.createElement('p');
        bucket.className = stringValues.stockStatus;
        
        if ( data  && data.pid) {
            if (data.stockStatus === 'PRODUCT_INVENTORY_IN_STOCK' && data.preorderEnabled === 'true') {
                
                bucket.className = bucket.className + ' product-pre-stock';
                stockStatus = siteLabels.preorder;
                addNotifyMe = true;
                
            } else if (data.stockStatus == 'PRODUCT_INVENTORY_IN_STOCK') {
                
                bucket.className = bucket.className + ' product-in-stock';
                stockStatus = siteLabels.instock;
                
            } else if (sku.backorderable && (data.stockStatus === 'PRODUCT_INVENTORY_OUT_OF_STOCK' || data.stockStatus === 'PRODUCT_INVENTORY_BACKORDERED')) {
                
                bucket.className = bucket.className + ' product-pre-stock';
                stockStatus = siteLabels.backorder; 
                addNotifyMe = true;
                
            } else if (data.stockStatus === 'PRODUCT_INVENTORY_OUT_OF_STOCK' || data.stockStatus === 'PRODUCT_INVENTORY_BACKORDERED') {
                
                bucket.className = bucket.className + ' product-out-of-stock';
                stockStatus = siteLabels.outofstock;
				addNotifyMe = true;
                
            } else if (getEcommActive() == true ){
                
                bucket.className = bucket.className + ' product-out-of-stock';
                stockStatus = siteLabels.outofstock; 
                
            }
        }// else {
         //   bucket.className = bucket.className + ' product-out-of-stock'; 
         //   stockStatus = siteLabels.outofstock;
        //}
        
        if (data.price === undefined || data.price === '') {
            bucket.className = bucket.className + ' expand-full-width';    
        } else {
            // if price and stock status, add white border to divide
            bucket.className = bucket.className + ' gray-border-left';
        }
        
        if (stockStatus  === siteLabels.instock) {
           bucket.style.display = 'none'; 
        }
        
         // price
        if ( data  && data.pid) {
            bucket2 = doc.createElement('p');
            if ((data.discountedPrice === data.price || rcLocaleJS == "fr-fr") && (!config.isSoftware || drLocale === 'en_US')) {
                bucket2.appendChild(doc.createTextNode(data.price));
            } else if (config.isSoftware){   
             	bucket2.appendChild(doc.createTextNode(data.noTaxPrice));  
            } else {
                bucket2 = doc.createElement('p');
                // resuse stock status var for new price
                string = doc.createElement('span');
                string.className = stringValues.crossedPrice;
                string.innerHTML = data.price;
                bucket2.appendChild(string);
                
                string = doc.createElement('span');
                string.className = stringValues.salesPrice;
                string.innerHTML = data.discountedPrice;
                bucket2.appendChild(string);
            }
            
            // make price full with if in stock
            if (stockStatus  === siteLabels.instock) {
                bucket2.className = stringValues.configPrice + ' expand-full-width';
            } else {
                bucket2.className = stringValues.configPrice;    
            }
            // append price
            container.appendChild(bucket2);
        }
        
        // append stock status
        bucket.appendChild(doc.createTextNode(stockStatus));
        container.appendChild(bucket);
        
                // model msrp
        if(sku.msrp != ""){
            bucket = doc.createElement('p');
            bucket.className = 'price';
            bucket.appendChild(doc.createTextNode(labels.currency + sku.msrp));
            container.appendChild(bucket);

            bucket = doc.createElement('p');
            bucket.className = 'price-detail';
            bucket.appendChild(doc.createTextNode(labels.suggested_price));
            container.appendChild(bucket);
        }

        
        // model number
        bucket = doc.createElement('p');
        bucket.className = 'open-config-model-number';
        bucket.appendChild(doc.createTextNode(sku.modelNumber));
        container.appendChild(bucket);
        
		// cta
        //if there is no ecom locale there wont be a buy button currently
        // Todo: Add task to to add resellers as ab utton when there is no ecom locale.
        if(ecomActive && data.pid !== "") { 
        	bucket = doc.createElement('a');
        	bucket.className = 'btn btn-small btn-info  ' + stringValues.configCta;
        	bucket.appendChild(doc.createTextNode(siteLabels.add_to_cart));
         
            if (stockStatus  === siteLabels.instock || stockStatus  ===  siteLabels.preorder || stockStatus  === siteLabels.backorder) {
                if (config.isSoftware && drLocale !== 'en_US') {
                    bucket.href='http://shop.seagate.com/store/sgateus/' + SEAGATE.localeInfo.softwareLocale + '/buy/productID.' + data.pid + '/quantity.1/Currency.' + 'USD';
                } else {
                    bucket.href='http://shop.seagate.com/store/sgateus/' + drLocale + '/buy/productID.' + data.pid + '/quantity.1/Currency.' + ecomInfo.currency; 
                }
            } else {
                bucket.className =  'btn btn-small btn-info disabled';
                bucket.href = '#';
            }
            // append cta
            container.appendChild(bucket);
        }else if (typeof ecomActive === 'undefined' && config.isSoftware ) {
            bucket.href='http://shop.seagate.com/store/sgateus/' + drLocale + '/buy/productID.' + data.pid + '/quantity.1/Currency.USD';
            container.appendChild(bucket);
        }
        
        // offers
        divBucket = doc.createElement('div');
        divBucket.className = 'offers-container';
        
        if (data.offersList !== undefined && data.offersList.length) {
           for (var i = 0; i < data.offersList.length; i ++) {
                bucket = doc.createElement('p');
                bucket.className = 'summary-offer';
                bucket.style.fontWeight = 'bold';
                bucket.appendChild(doc.createTextNode(data.offersList[i].offerName));
                
                var pitch = doc.createElement('p');
                pitch.className = 'offer-pitch';
                pitch.style.fontWeight = 'normal';
                pitch.appendChild(doc.createTextNode(data.offersList[i].offerPitch));
                
                // append offer
                bucket.appendChild(pitch);
                divBucket.appendChild(bucket);
            } 
        }
        
        if(addNotifyMe && data.pid) { 
            container.appendChild($S.notifyMe.link(data.pid, document.getElementById('localeId').value));
        }
            
        container.appendChild(divBucket);     
        //add resellers link
        if(comparisonEnabled && !config.isSoftware){
        	divBucket = doc.createElement('div');
            divBucket.className = 'resellersLink';
            if(isPriceSpider){
            	divBucket.innerHTML = '<script>PriceSpider.insertWidget({"sku":"' + sku.modelNumber + '"})</script>\n';
            } else {           
            	var bhHtml = "";
                if(config.enableBH && config.pmidMap[sku.modelNumber] != "") { 
                    bhHtml = '<div class="bh"><img src="/www-content/web-apps/_shared/images/b-and-h-logo.png"> <a class="btn btn-primary btn-small" href="http://redir.pricespider.com/redirect/?cid=582e71f44c10ade32ad8120c&pmid=' + config.pmidMap[sku.modelNumber] + '" data-toggle="modal">BUY NOW</a></div>';
                }
                divBucket.innerHTML = bhHtml + buildWtbResellers(wtb_categories);
              
            }
            container.appendChild(divBucket);
        }
        
        return container;   
    };
    
	
	//syncs digital river data with the data in memory, the ecomInfoObjs.
    var syncDrObjects = function(drDataObj, isError) {
        if (!drDataObj) {
            return;
        } 
        var ecomInfo = {};
     
        if (!isError) { 
           for (var i = 0; i < drDataObj.length; i++) {
                for (var x = 0; x < ecomInfoObjs.length; x++) {
                    if (drDataObj[i].pid === ecomInfoObjs[x].pid) {
                        ecomInfoObjs[x].data = drDataObj[i];
                        ecomInfoObjs[x].returned = true;

                        if (ecomInfoObjs[x].domObj) {
                            promises.push(ecomInfoObjs[x]);
                        }
                    }
                }
            } 
        } else {
            for (var i = 0; i < drDataObj.length; i++) {
                getEcomInfoObj(drDataObj[i].modelNumber, function(obj) {
                    if(obj) {
                        obj.data = { pid : '', price : '', discountedPrice: '', noTaxPrice : ''};
                        obj.returned = true;
                        promises.push(obj);   
                    }    
                });
            }
        }// else
    };
    
	//gets in income info object form machign a string and executes a callback if found 
    var getEcomInfoObj = function(sku, cb) {
        var objs = ecomInfoObjs;
        
        objs.forEach(function(obj) {
            if(sku === obj.sku.modelNumber) {
                //passobj execuate call back
                cb(obj);
                return ;
            }
        });
		
    };
 
    // calls the dr api, passes the return data syncDrObjects() and where 
    // it gets sent to the promise loop.
    var getDrData = function (skus) {
        syncDrObjects(skus, true); //part of ecomm shutdown
        return;
        var locale = drLocale, cur =  currency,
            pid = '', url = '', numSkus = skus.length;
      
        // set pid and ajax url
        if (skus && numSkus > 1) {
            for (var i = 0; i < numSkus; i++) {
                if (skus[i].drProductId && i !== numSkus -1) {
                    pid += skus[i].drProductId + ',';
                } else {
                    pid += skus[i].drProductId;
                }
            }
            
            url = '/ww/jsp/common/drproduct.jsp?drProductId=' + pid + '&popName=value-add&drLocale=' + locale + '&currency=' + cur;
            
        } else if (skus[0].drProductId) {
            pid = skus[0].drProductId;
            url = '/ww/jsp/common/dr.jsp?drProductId=' + pid + '&popName=' + 'value-add' + '&drLocale=' + locale + '&currency=' + cur;
        }
        
        // TEST 
        if(config.debug) {
			url = 'data/dr-single-license.json';
        }
        // END TEST
		
        if (pid) {  
            
            $.ajax({
                url: url,
                dataType: "json",
                success : function(data) { 
                    if (data.storeProduct !== undefined && data.storeProduct.length) { 
						
                        // TEST         
                        if(config.debug) {
							
                            if (data.storeProduct.length === 1) {
								changePid(pid, data.storeProduct[0]); 
							} 
                        }
                        // END TEST
                        syncDrObjects(data.storeProduct);
                           
                    } else { 
                        syncDrObjects([{ pid : pid, price : '', discountedPrice: '', noTaxPrice : ''}]);
                    }                    
                },
                
                error : function (data, err) {  
                    syncDrObjects(skus, true);
                }
            }); // end ajax
            
        } else { 
            syncDrObjects(skus, true);
        } // END if (pid) 
        
    
    };
    
    
    //looks through an array or the localProductInfoStruct object for a modelNumber
    var getModelObject = function(modelNumber, productArray) {
        var products, skuNum;
        
        if(productArray){
            products = productArray;
        } else {
            products = localProductInfoStruct.releaseList[0].modelList;
        }
        
        for (var i = 0; i < products.length; i++) {
             if (products[i].modelNumber === modelNumber) {
                 skuNum =  products[i];
                 break;
             }
         }
         
         return skuNum || false;
    };
    
	//sets and resets event handlers when needed. Currently on runs in Init
    var resetEventHandlers = function () {
        var slides = numColumns,
            secondToLast = numColumns - 1,
            configSlide = 1;
    
        $('#' + config.parent + ' .' + stringValues.colorConfig).unbind('click');
        $('#' + config.parent + ' .' + stringValues.attrClass).unbind('click');       
        $('#' + config.parent + ' #config-swipe-next').unbind('click');
        $('#' + config.parent + ' #config-swipe-prev').unbind('click');
        $('#' + config.parent + ' .capacity-column' ).unbind('swiperight');
        $('#' + config.parent + ' .capacity-column' ).unbind('swipeleft');
        //$('.show-resellers' ).unbind('click');
		
		//$('.show-resellers').on('click', function(e) { 
		//	e.preventDefault();
		//	$('.resellers-lightbox').fadeIn();	
		//});
		
        $(window).resize(function () {
            contWidth = $('#' + config.parent).width();
           
            if ($(this).width() <= 767) {
                
                // reset all
                configSlide = 1;
                $('#config-swipe-prev').hide();
                $('#config-swipe-next').show();
                $('#open-config').css({'width': contWidth * slides, 'margin-left': 0});
                
                if(slides > 1) { 
                    $('.capacity-column').css('width', (contWidth - 10));
                }else{
                    $('.capacity-column').css('width', (contWidth));
                }
                
            } else {
                $('#config-swipe-prev').hide();
                $('#config-swipe-next').hide();
                
                $('.capacity-column').each(function () {
                    $(this).attr('style', '');
                });
                
                $('#open-config').attr('style', '');;    
            }
        }); // window resize
        
        if(slides > 1) { 
            $( '.capacity-column' ).on( 'swipeleft', function(e) {
                if ($(window).width() > 767) {
                    return;
                }
                
                if (configSlide === slides) {
                    e.preventDefault();
                    return;
                } else {
                    
                    $('#open-config').animate({
                        marginLeft : -(((contWidth * configSlide)) - 30)
                    });
                    
                    if (configSlide === secondToLast) {
                        $('#config-swipe-next').hide();
                    }
                    
                    $('#config-swipe-prev').show();
                    configSlide++;
                }
            });// swipe left event
            
            $( '.capacity-column' ).on( 'swiperight', function(e) {
                if ($(window).width() > 767) {
                    return; 
                }
                  
                if (configSlide === 1) {
                    return;
                } else {
                    // if not at first, get current margin left
                    var leftMargin = parseInt($('#open-config').css('margin-left'));
                    if (configSlide === secondToLast) {
                        $('#config-swipe-prev').hide();
                    }
                    
                    $('#config-swipe-next').show();
                    $('#open-config').animate({
                        marginLeft : (leftMargin +(contWidth))
                    }); 
                    
                    configSlide--;
                }
            });// swipe right event
             
            $('#config-swipe-next').on('click', function () {
                
                if (configSlide === numColumns) {
                    //if it's the last one, reset to slide one
                    //set only as a last measure if display is still on
                    configSlide = 1;
                    $('#config-swipe-prev').hide();
                    $('#open-config').animate({marginLeft: 0});
                    
                } else {
                    $('#open-config').animate({
                        marginLeft : -(((contWidth * configSlide)) - 30)
                    });
                    
                    if (configSlide === secondToLast) {
                        $(this).hide();
                    }
                    $('#config-swipe-prev').show();
                    configSlide++;
                }   
            });// swipe next click event
            
            $('#config-swipe-prev').on('click', function () {
                if (configSlide === 1) {
                    return;
                }
                
                //if currently the scond slide, reset back to one
                if (configSlide === 2) {
                    $('#config-swipe-prev').hide();
                    $('#open-config').animate({marginLeft: 0});
                } else {
                    var leftMargin = parseInt( $('#open-config').css('margin-left') );
                    
                    $('#config-swipe-next').show();
                    
                    $('#open-config').animate({
                        marginLeft : (leftMargin + contWidth)
                    }); 
                    
                }
                 configSlide--;    
            });// swipe prev click event
        } // if slides > 1 
        
        $('#' + config.parent + ' .' + stringValues.colorConfig).on('click', function () {
            
            if ($(this).hasClass(stringValues.colorConfigCurrent)) {
                return;
            }
            
            
            var sku = $(this).attr('data-model'),
                parent = $(this).parent().parent(),
                image = image = $(parent).find('.selected-sku-img img'),
                reseller = $(parent).find('.reseller-link'),
                containerObj = $(parent).find('.dr-dynamic-data');
               
            $(parent).find('.color-label').html(' - ' + $(this).attr('title'));
            
            // update product image and attributes
            $(image).attr('src', $(this).find('.drive-color').attr('data-image-src'));
            $(image).attr('alt', $(this).find('.drive-color').attr('alt'));
            $(image).attr('title', $(this).find('.drive-color').attr('title'));
            
            // get dr info
            getSkuEcomData(sku, containerObj);
            
            // set spinner for data
            $(containerObj).html(imageSpinner()); 
            
            // visually show selection
            $(this).parent().find('.' + stringValues.colorConfigCurrent).removeClass(stringValues.colorConfigCurrent);
            $(this).addClass(stringValues.colorConfigCurrent);
            
            // remove current resellers link
            $(reseller).html('');
            
        }); // color select click event
        
        //string attribute event
        $('.' + stringValues.attrClass).on('click', function () {
            
            if ($(this).hasClass(stringValues.attrCurrentClass)) {
                return;
            }
            
            var sku = $(this).attr('data-model'),
                parent = $(this).parent().parent(),
                image = $(parent).find('.selected-sku-img img'),
                reseller = $(parent).find('.reseller-link'),
                containerObj = $(parent).find('.dr-dynamic-data');
                           
            // update product image
            $(image).attr('src', $(this).find('span').attr('data-image-src'));
            $(image).attr('alt', $(this).find('span').attr('data-model-title'));
            $(image).attr('title', $(this).find('span').attr('data-model-title'));
            // get dr info
            getSkuEcomData(sku, containerObj);
            
            // set spinner for data
            $(containerObj).html(imageSpinner()); 
            
            // visually show selection
            $(this).parent().find('.' + stringValues.attrCurrentClass).removeClass(stringValues.attrCurrentClass);
            $(this).addClass(stringValues.attrCurrentClass);
            
            // remove current resellers link
            $(reseller).html('');
        }); 
    };//reset event handlers
    
	//the api. init is the only method beeing used and needed. You can also expose other methods if they were needed. 
    return {
        init: init,
        ecomInfoObjs: ecomInfoObjs 
    };   
})(window);

//helper functions

function getWTBResellers(country) {

     var wtb_categories = ProductInfoStruct.releaseList[0].categories;
     if(wtb_categories == null || wtb_categories == ""){ 
     	$('#wtbContent').html(labels.not_available_resellers);
     	return;
     }
     if(country == "EM") country = "AE";
     var wtb_language = rcLocaleJS.substring(0, 2) + rcLocaleJS.slice(2).toUpperCase();
	 $('#wtbContent').load('/ww/resellersData?language=' + wtb_language + '&country=' + country + '&categories=' + wtb_categories + '&brand=' + brand);
}


function sortColors (sortedProducts) {
    var colorSortedProds = jQuery.extend(true, {}, sortedProducts);
    var counters = [];
    var sortedKeys = Object.keys(sortedProducts); // 
    
    for(h=0; h<sortedKeys.length; h++){
   	   counters[h] = 0 ;
    }
    // loop through the colors
    for (k=0; k<ProductInfoStruct.releaseList[0].colorsList.length; k++){ 
        // loop through the columns
    	for(l=0; l<sortedKeys.length; l++){ 
    	    // loop through products of current column in source object
    	    for (m=0; m<sortedProducts[sortedKeys[l]].products.length; m++){ 
    	    	//if color of product matches the one in list 	         
    	    	if(sortedProducts[sortedKeys[l]].products[m].colorCode.indexOf(ProductInfoStruct.releaseList[0].colorsList[k].colorCode) !== -1){ 
    	    	    //copy the matched project to the correct column in the target object.
		    		colorSortedProds[sortedKeys[l]].products[counters[l]] = jQuery.extend(true, {}, sortedProducts[sortedKeys[l]].products[m]);
		    		//increment the counter of the current target object's column.
		    		counters[l]++; 
    	    	}
    	    }
    	}
    }
	return colorSortedProds;
}
