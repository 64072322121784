/*
	The following contains the notify me functionality and UI coponents for the "notify me when in stock" feature.
	It is part of the configurator and used anywhere the configurato it is used. 
*/
(function($S, $) {
	var notifyMe = {};
	
	notifyMe.isFirst = true;
	notifyMe.on = false;
	
    notifyMe.makeNotifyMeModal = function(pid, locale) {
        var modal = document.getElementById('NotifyMe'),
            lightBox = document.createElement('div'),
            modalBox = document.createElement('div'),
            modalBody = document.createElement('div'),
            button = document.createElement('button'),
			self = this;
        
        lightBox.className = 'notifyme-lightbox';
        
        modalBody.className = 'modal-body';
        modalBody.appendChild( notifyMe.iframe(pid, locale) );
        
        button.className = 'nav-footer-close close';
		button.id = "close-notify-me";
        button.appendChild(document.createTextNode('X'));
        
        modalBody.appendChild(button);
        
		modalBox.id = 'NotifyMe';
		modalBox.className = 'modal fade in';
		$(modalBox).attr('aria-hidden', 'true');
		modalBox.appendChild(modalBody);

		lightBox.appendChild(modalBox);
		document.body.appendChild(lightBox);   
     
		
		return this;
    };
    
    notifyMe.iframe = function(pid, locale) { 
        
        if(!pid || !locale) {
			console.log(pid, locale)
            return '';   
        }
         
        var iframe = document.createElement('iframe');
        
        //locale example it_IT
        iframe.src = 'http://gc.digitalriver.com/store/sgateus/DisplayNotifyMeFormPage/productID.' + pid + '?locale=' + locale
        iframe.id = 'DrNotifyForm';
        
        return iframe;
    };
    
    notifyMe.link = function(pid, locale) {
        var linkDiv = document.createElement('div');
        var link = document.createElement('a')
			self = this, label = labels.notify_me || 'Notify me when this model is in-stock';
        
        link.appendChild(document.createTextNode(label));
        link.href = "#NotifyMe";
        link.className = 'showNotifyMe';
        $(link).attr('data-toggle', 'modal');
        $(link).attr('data-locale', locale);
		$(link).attr('data-pid', pid);
		
        linkDiv.appendChild(link);
        linkDiv.className = 'pdp-get-alert';
        
		
		if(self.isFirst) {
			notifyMe.eventHandler();
		
			self.isFirst = !self.isFirst;
		}
		
        return linkDiv;
    };
	
	notifyMe.hideNotifyMe = function(){
		var self = this;
	 	$('#NotifyMe').fadeOut(400).remove();
		$('.modal-backdrop').removeClass('in').slideUp(500, function() {
			$('.notifyme-lightbox').remove();
			$(this).remove();
		});
		
		$('body').removeClass('no-scroll');
		
		self.on = !self.on;
	};
	
	notifyMe.showNotifyMe = function(){
		var self = this;
		
		$('.modal-backdrop').fadeIn(400, function() {
			$('#NotifyMe').addClass('in').removeClass('hide');	
		});
		
		$('body').addClass('no-scroll');
		
	};
	
	notifyMe.eventHandler = function() {
		var self = this; 
		
		$('body').on('click', function(e) {
			
			if( $(e.target).hasClass('modal-backdrop') ) {
				self.hideNotifyMe();
				self.on = !self.on;
			}
			
			if( $(e.target).hasClass('showNotifyMe') ){
				e.target.className;
			   //append light box
				self.makeNotifyMeModal( $(e.target).attr('data-pid'), $(e.target).attr('data-locale') );
				self.showNotifyMe();
			}
			
			if( $(e.target).hasClass('nav-footer-close') ){
				self.hideNotifyMe();
			}
		
		});
		
		
	};

	//Add to SEAGATE namespace	
	$S.notifyMe = notifyMe;
})(SEAGATE, jQuery);