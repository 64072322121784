//polyfill
( function() {
    'use strict';
    //foreach for ie8
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function(callback, thisArg) {
            var T, k;

            if (this == null) {
                throw new TypeError(' this is null or not defined');
            }

            // 1. Let O be the result of calling ToObject passing the |this| value as the argument.
            var O = Object(this);

            // 2. Let lenValue be the result of calling the Get internal method of O with the argument "length".
            // 3. Let len be ToUint32(lenValue).
            var len = O.length >>> 0;

            // 4. If IsCallable(callback) is false, throw a TypeError exception.
            // See: http://es5.github.com/#x9.11
            if ( typeof callback !== "function") {
                throw new TypeError(callback + ' is not a function');
            }

            // 5. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 1) {
                T = thisArg;
            }

            // 6. Let k be 0
            k = 0;

            // 7. Repeat, while k < len
            while (k < len) {

                var kValue;

                // a. Let Pk be ToString(k).
                //   This is implicit for LHS operands of the in operator
                // b. Let kPresent be the result of calling the HasProperty internal method of O with argument Pk.
                //   This step can be combined with c
                // c. If kPresent is true, then
                if ( k in O) {

                    // i. Let kValue be the result of calling the Get internal method of O with argument Pk.
                    kValue = O[k];

                    // ii. Call the Call internal method of callback with T as the this value and
                    // argument list containing kValue, k, and O.
                    callback.call(T, kValue, k, O);
                }
                // d. Increase k by 1.
                k++;
            }
            // 8. return undefined
        };
    }
    
    // create cross-browser support for indexOf
    if (!Array.prototype.indexOf) {
        Array.prototype.indexOf = function(obj) {
            for (var i = 0; i < this.length; i++) {
                if (obj === this[i])
                    return i;
            }
            return -1;
        };
    }
    
    
    if (!Array.prototype.map) {
        // Array.prototype.map
        Array.prototype.map = function map(callback, scope) {
            for (var array = this, arrayB = [], index = 0, length = array.length, element; index < length; ++index) {
                element = array[index];
    
                arrayB.push(callback.call(scope || window, array[index], index, array));
            }
            return arrayB;
        };
    }
    
    if (!String.prototype.trim) {
        // String.prototype.trim
        String.prototype.trim = function trim() {
            return this.replace(/^\s+|\s+$/g, '');
        };
    }
    
    if (!Function.prototype.bind) {
        // Function.prototype.bind
        Function.prototype.bind = function bind(scope) {
            var callback = this, prepend = Array.prototype.slice.call(arguments, 1), Constructor = function() {
            }, bound = function() {
                return callback.apply(this instanceof Constructor && scope ? this : scope, prepend.concat(Array.prototype.slice.call(arguments, 0)));
            };
    
            Constructor.prototype = bound.prototype = callback.prototype;
            return bound;
        };
    }

    if (!Array.prototype.filter) {
        // Array.prototype.filter
        Array.prototype.filter = function filter(callback, scope) {
            for (var array = this, arrayB = [], index = 0, length = array.length, element; index < length; ++index) {
                element = array[index];
    
                if (callback.call(scope || window, element, index, array)) {
                    arrayB.push(element);
                }
            }
    
            return arrayB;
        };
    }
}()); 








